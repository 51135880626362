let state;
let resolve;
const permissionsInitializedPromise = new Promise((res) => {
  resolve = res;
});

export class ExternalSharingStore {
  static updateState(newState) {
    state = newState;
    if (state?.subscriptions?.permissionsInitialized) {
      resolve();
    }
  }

  static async getAccessLevels() {
    await permissionsInitializedPromise;
    return state?.subscriptions?.permissions?.access_levels ?? [];
  }

  static async getGroupToken() {
    await permissionsInitializedPromise;
    return state?.subscriptions?.permissions?.group_token;
  }

  static get isCurationActive() {
    return !!(
      state?.userPreferencesReducer?.user?.is_curator
      && state?.userPreferencesReducer?.curationMode
    );
  }
}

export default ExternalSharingStore;
