import moment from 'moment';
import { EARLIEST_SEARCH_DATE_GOLD_USER } from '../environment';

export const PAST_MAX_DATE_ERR = 'pastMaxDateErr';
export const FUTURE_MAX_DATE_ERR = 'futureMaxDateErr';
export const DATE_RANGE_ERR = 'dateRangeErr';
export const DATE_FORMAT_ERR = 'dateFormatErr';
export const TIME_FORMAT_ERR = 'timeFormatErr';
export const MAX_SPECIFIC_DAYS_SELECTION = 30;
export const MAX_DATE = moment(EARLIEST_SEARCH_DATE_GOLD_USER).utc();
export const FILINGS_MAX_DATE = moment(new Date(1864, 0, 1)).utc();
export const CHART_MAX_DATE = moment().subtract(30, 'days').utc();
export const SENTIMENT_STORIES_MAX_DATE = moment().subtract(30, 'days').utc();
export const EARLIEST_DATE_RANGE_ERR = 'earliestDateRangeErr';
export const EARLIEST_DAY_ERROR = 'earliestDayError';
export const EARLIEST_MIN_PERIOD_ERR = 'earliestMinPeriodErr';
export const MAX_SELECTED_DOMAINS = 100;
export const NUMBER_VALUE_ERR = 'numberValueError';
export const MAX_SELECT_TIME_PERIODS = {
  months: 1,
  weeks: 4,
  days: 30,
  hours: 720,
  minutes: 43200,
};
