import { publicCompaniesInstance as PublicCompaniesAPI } from './topicsApi';

import {
  getAPICachingTime, returnAPICachingData,
  returnAPICachingHeader,
} from '../../helpers/helpers';
import { removeEmptyProperties } from '../../helpers/commonHelpers';
import { cachingVeryLow } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  fullDetailsList: [],
  gainersLosersChartList: [],
};

export default class PublicCompaniesSvc {
  static async getPublicChartsSymbolData(selector, params) {
    try {
      const key = `charts_${selector}`;

      const cacheData = returnAPICachingData(caches, key, params);
      if (cacheData) return cacheData;

      // Do not add an await statement here.
      // It would help to use the same cache if 2+ requests are sent immediately
      const response = RetryAPICall(PublicCompaniesAPI, `charts/${selector}`, {
        params,
      }).then(({ data }) => (Array.isArray(data) ? data : []));

      if (!caches[key]) {
        caches[key] = [];
      }

      caches[key].unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response,
      });

      return response;
    } catch {
      return [];
    }
  }

  static async getPublicCompaniesStats(params) {
    try {
      removeEmptyProperties(params);
      const {
        selectors,
        graph,
        minigraph,
        market_hours: marketHours,
      } = params;
      let mainTicker = null;

      if (Number.isInteger(graph)) {
        const date = new Date();
        date.setDate(date.getDate() - graph);
      }

      let stats = (
        await new Promise((resolve, reject) => {
          let rejectedCount = 0;

          selectors.forEach(async (selector) => {
            try {
              const data = await this.getPublicChartsSymbolData(selector, {
                graph,
                minigraph,
                market_hours: marketHours,
              });
              if (data) {
                mainTicker = selector;
                resolve(data);
              } else {
                throw new Error();
              }
            } catch (e) {
              rejectedCount += 1;
              if (rejectedCount === selectors.length) reject();
            }
          });
        })
      );

      if (Number.isInteger(graph) && Array.isArray(stats)) {
        const oldLength = stats.length - 1;
        stats = stats.filter((stat, index) => (
          index % Math.floor(stats.length / 20) === 0 || index === oldLength
        ));
      }

      return {
        mainTicker,
        stats: Array.isArray(stats) ? (
          stats.filter((stat) => stat.price > 0 && stat.volume_24h >= 0).map((stat) => ({
            ...stat,
            last_updated: new Date(stat.updated_at * 1000).toISOString(),
            market_cap_usd: stat.market_cap,
            price_usd: stat.price,
            volume_usd_24h: stat.volume_24h,
          }))
        ) : [{}],
      };
    } catch (e) {
      return {
        error: true,
        mainTicker: null,
        stats: [],
      };
    }
  }

  static async getPublicCompaniesFullDetails(params = {}) {
    try {
      removeEmptyProperties(params);
      const fields = ['page_limit', 'page', 'symbol'];
      const defaultParams = {
        page: 1,
        page_limit: 200,
      };

      fields.forEach((field) => {
        if (!(field in params) && field in defaultParams) params[field] = defaultParams[field];
      });

      const { data: fullDetails } = await new Promise(async (resolve, reject) => {
        if ('symbol' in params) {
          const opts = {
            ...params,
            symbols: params.symbol,
          };
          delete opts.symbol;
          const res = await this.getPublicCompaniesFullData(opts);
          if (res.data) {
            return resolve(res);
          }
          return reject();
        }

        const {
          symbols,
          per,
          page,
          market_hours: marketHours,
        } = params;
        if (!Array.isArray(symbols)) {
          const { per, page } = params;
          const res = await this.getPublicCompaniesFullData({
            per,
            page,
          });
          return resolve(res);
        }

        if (!symbols.length) {
          reject();
          return;
        }

        const res = await this.getPublicCompaniesFullData({
          per,
          page,
          symbols: symbols.join(','),
          market_hours: symbols.length === 1 ? marketHours : undefined,
        });

        if (res.data) {
          return resolve(res);
        }

        return reject();
      });

      const mainTicker = fullDetails && fullDetails.length ? fullDetails[0].ticker : null;
      const dataObj = {
        fullDetails: fullDetails.map((details) => ({
          ...details,
          price: details.latest_price || details.price,
          price_usd: details.latest_price || details.price,
          market_cap_usd: details.market_cap_usd || details.market_cap,
          volume_usd_24h: details.volume_usd_24h || details.volume_price_24h,
        })),
        mainTicker,
      };

      return dataObj;
    } catch (e) {
      return {
        error: true,
        fullDetails: [{
          ticker: null,
          price: 0,
          price_usd: 0,
          market_cap: 0,
          market_cap_usd: 0,
          volume_price_24h: 0,
          volume_usd_24h: 0,
          volume_percentage_of_total: 0,
          updated_at: null,
        }],
        mainTicker: null,
      };
    }
  }

  static async getPublicCompaniesFullData(params = {}) {
    try {
      const cacheData = returnAPICachingData(caches, 'fullDetailsList', params);
      if (cacheData) return cacheData;

      // Do not add await statement here.
      // It would help to use same cache if 2+ requests are send immediately
      const res = RetryAPICall(PublicCompaniesAPI, 'full_details', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      caches.fullDetailsList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: res,
      });

      return res;
    } catch (e) {
      return {
        data: [],
      };
    }
  }

  static async getPublicCompaniesGainersLosers(params = {}) {
    try {
      removeEmptyProperties(params);
      const fields = ['page_limit', 'page', 'type'];
      const defaultParams = {
        page_limit: 10,
        page: 1,
      };

      fields.forEach((field) => {
        if (!(field in params)) params[field] = defaultParams[field];
      });

      const cacheData = returnAPICachingData(caches, 'gainersLosersChartList', params);
      if (cacheData) return cacheData;

      const { data } = await RetryAPICall(PublicCompaniesAPI, 'gainers_losers_chart', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      const response = Array.isArray(data) ? data.map((item) => ({
        ...item,
        name: item.companyName,
        symbol: item.ticker,
        price_usd: item.latest_price,
        percent_change_24h: item.priceChangePercentForChosedPeriod,
        volume_usd_24h: item.volume_price_24h,
        volume_percent_change_24h: item.volume_percentage_of_total,
        market_cap_usd: item.market_cap,
        market_cap_percent_change_24h: item.market_cap_percentage_of_total,
        url: item.cfUrl,
      })) : [];

      caches.gainersLosersChartList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response,
      });

      return response;
    } catch (e) {
      return e;
    }
  }
}
