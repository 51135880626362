export default function deepMerge(target, source) {
  // Check if the source is an object
  if (typeof source !== 'object' || source === null) {
    return source;
  }

  // Ensure target is an object
  if (typeof target !== 'object' || target === null) {
    // eslint-disable-next-line no-param-reassign
    target = Array.isArray(source) ? [] : {};
  }

  // Iterate through each key in the source
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      // Check if the value is an object
      if (typeof source[key] === 'object' && source[key] !== null) {
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }

  return target;
}
