import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import withComponentName from '../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const Preloader = ({
  loading,
  className,
  color,
  top,
  style,
  static: staticPositioned,
  centered,
  hasBigScreenDesign,
  transform,
  transformYCentered,
  transformYCentered100,
}) => {
  let preloaderClass = cx({
    loading,
    loading_active: loading,
    static: loading && staticPositioned,
    centered: loading && centered,
    transform: loading && transform,
    'transform-centered': loading && transformYCentered,
    'transform-centered-100': loading && transformYCentered100,
    loading_hidden: !loading,
    big_screen: hasBigScreenDesign,
  });

  if (loading) preloaderClass += ` ${className}`;

  return (
    <div
      className={preloaderClass}
      style={{
        ...style,
        backgroundColor: color,
        top,
      }}
    >
      <div className={Styles.dot} />
    </div>
  );
};

Preloader.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  top: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  static: PropTypes.bool,
  centered: PropTypes.bool,
  transform: PropTypes.bool,
  transformYCentered: PropTypes.bool,
  transformYCentered100: PropTypes.bool,
  style: PropTypes.object,
};

Preloader.defaultProps = {
  loading: false,
  className: '',
  color: '',
  top: '',
  static: false,
  centered: false,
  transform: false,
  transformYCentered: false,
  transformYCentered100: false,
  style: {},
};

export default withComponentName(memo(Preloader));
