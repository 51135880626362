export const CRYPTOCURRENCIES = 'CRYPTOCURRENCIES';
export const PRIVATE_COMPANIES = 'PRIVATE_COMPANIES';
export const PUBLIC_COMPANIES = 'PUBLIC_COMPANIES';
export const PEOPLE = 'PEOPLE';
export const COMMODITIES = 'COMMODITIES';
export const INDICES = 'INDICES';
export const FOREIGN_EXCHANGE = 'FOREIGN_EXCHANGE';
export const EXCHANGE_TRADED_FUNDS = 'EXCHANGE_TRADED_FUNDS';
export const CLOSED_END_FUNDS = 'CLOSED_END_FUNDS';
export const PRIVATE_INVESTOR = 'PRIVATE_INVESTOR';
export const UK_SINGLE_COMPANIE = 'UK_SINGLE_COMPANIE';

export const SLUG_TO_TYPE_MAPPING = new Map([
  ['cryptocurrencies', CRYPTOCURRENCIES],
  ['private-companies', PRIVATE_COMPANIES],
  ['stocks', PUBLIC_COMPANIES],
  ['people', PEOPLE],
  ['commodities', COMMODITIES],
  ['indices', INDICES],
  ['foreign-exchange', FOREIGN_EXCHANGE],
  ['funds', EXCHANGE_TRADED_FUNDS],
  ['closed-end-funds', CLOSED_END_FUNDS],
]);

export const TYPE_TO_SLUG_MAPPING = new Map(
  [...SLUG_TO_TYPE_MAPPING.entries()]
    .map(([key, value]) => [value, key]),
);
