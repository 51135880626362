export const SUB_INDUSTRY_TOPIC_CLASS = 'SUB-INDUSTRY-TOPIC';
export const INDUSTRY_TOPIC_CLASS = 'INDUSTRY-TOPIC';
export const CATEGORY_TOPIC_CLASS = 'CATEGORY-TOPIC';
export const SECTOR_TOPIC_CLASS = 'SECTOR-TOPIC';
export const SUB_INDUSTRY_ONLY = 'SUB-INDUSTRY-ONLY';
export const INDUSTRY_ONLY = 'INDUSTRY-ONLY';
export const CATEGORY_ONLY = 'CATEGORY-ONLY';
export const SUB_INDUSTRY = 'SUB-INDUSTRY';
export const TOPIC_CLASS = 'TOPIC';
export const DCSC_SECTORS = 'dcsc-sectors';
export const INDUSTRY = 'INDUSTRY';
export const CATEGORY = 'CATEGORY';
export const SECTOR = 'SECTOR';

export const LOCATION = 'LOCATION';
export const CONTINENT = 'continent';
export const SUBCONTINENT = 'subcontinent';
export const COUNTRY = 'country';
export const STATE = 'state';
export const CITY = 'city';
export const AREA = 'AREA';
export const PLACE = 'PLACE';

export const LEVEL_1 = 'level1';
export const LEVEL_2 = 'level2';
export const LEVEL_3 = 'level3';
export const LEVEL_4 = 'level4';

export const LOCATION_TYPES = [
  LOCATION, CONTINENT, SUBCONTINENT, COUNTRY,
  STATE, CITY, AREA, PLACE,
];

export const DCSC_TYPES = [
  LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4,
];
