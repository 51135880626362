import moment from 'moment';

import {
  ONE_DAY,
  ONE_WEEK,
  ONE_MONTH,
  THREE_MONTH,
  ONE_YEAR,
  ALL,
  FIVE_YEAR,
} from '../data/directory/topic_classes/ChartFilters';

const validateEndDate = (date) => {
  if (moment(date).isSameOrBefore(moment())) {
    return date.utcOffset(0, true).format();
  }
  return moment().utcOffset(0, true).format();
};

export function getSentimentsChartStoriesDateInterval(date, timeFilter) {
  const startDate = moment(date).utcOffset(0, true).format();
  switch (timeFilter) {
    case ONE_DAY:
      return {
        startDate,
        endDate: moment(date).add(1, 'hours').utcOffset(0, true).format(),
      };
    case ONE_WEEK:
      return {
        startDate,
        endDate: moment(startDate).add(1, 'days').utc().format(),
      };
    case ONE_MONTH:
      return {
        startDate,
        endDate: moment(startDate).add(1, 'days').utc().format(),
      };
    case ONE_YEAR:
    case FIVE_YEAR:
    case ALL:
    case 'all':
      return {
        startDate: moment(startDate).startOf('month').utc().format(),
        endDate: validateEndDate(moment(startDate).endOf('month')),
      };
    default:
      return {};
  }
}

export function getChartStoriesDateInterval(date, timeFilter) {
  switch (timeFilter) {
    case ONE_DAY:
    case ONE_WEEK:
      return {
        startDate: moment(date).subtract(1, 'hours').utcOffset(0, true).format(),
        endDate: validateEndDate(moment(date).add(1, 'hours')),
      };
    case ONE_MONTH:
    case THREE_MONTH:
    case ONE_YEAR:
    case FIVE_YEAR:
    case ALL:
    case 'all':
      return {
        startDate: moment(date).subtract(12, 'hours').utcOffset(0, true).format(),
        endDate: validateEndDate(moment(date).add(12, 'hours')),
      };
    default:
      return {};
  }
}

export const getChartRangeDates = (props) => {
  const { currentDate, prevDate, nextDate } = props;

  // In case of first point of data or last point of data we can set into current date
  let startDate = currentDate;
  let endDate = currentDate;

  // Using hours as we have to add this feature for 1year or more data filter that return dates
  if (prevDate) {
    const prevDiff = moment(new Date(currentDate)).diff(moment(new Date(prevDate)), 'hours');
    startDate = moment(currentDate).subtract(prevDiff / 2, 'hours').utc().format('YYYY-MM-DD HH:mm');
  }

  if (nextDate) {
    const nextDiff = moment(new Date(nextDate)).diff(moment(new Date(currentDate)), 'hours');
    endDate = moment(currentDate).add(nextDiff / 2, 'hours').utc().format('YYYY-MM-DD HH:mm');
  }

  return { startDate, endDate };
};

export const getSentimentRange = (value) => ({
  sentiment_gte: value - 30 >= -100 ? value - 30 : -100,
  sentiment_lte: value + 30 <= 100 ? value + 30 : 100,
});

export const getSentimentEtoroRange = (value) => {
  if (value < -19) {
    return {
      sentiment_gte: -100,
      sentiment_lte: -20,
    };
  }
  if (value <= 19) {
    return {
      sentiment_gte: -19,
      sentiment_lte: 19,
    };
  }
  return {
    sentiment_gte: 20,
    sentiment_lte: 100,
  };
};
