import {
  convertDate,
  getAPICachingTime,
  returnAPICachingData,
  returnAPICachingHeader,
} from '../../helpers/helpers';
import { removeEmptyProperties } from '../../helpers/commonHelpers';

import { cryptoInstance as CryptoTopicsAPI } from './topicsApi';
import { cachingVeryLow } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  marketShareList: [],
  volumeChartList: [],
  cryptoFullDetailsList: [],
  gainersLosersChartList: [],
};

export class CryptocurrenciesSvc {
  static async getCryptoChartsData(currency, params) {
    const key = `charts_${currency}`;

    const cacheData = returnAPICachingData(caches, key, params);
    if (cacheData) return cacheData;

    // Do not add an await statement here.
    // It would help to use the same cache if 2+ requests are sent immediately
    const response = RetryAPICall(CryptoTopicsAPI, `charts/${currency}`, {
      params,
    }).then(({ data }) => data);

    if (!caches[key]) {
      caches[key] = [];
    }

    caches[key].unshift({
      params,
      expDate: getAPICachingTime(cachingVeryLow),
      response,
    });

    return response;
  }

  static async getCryptocurrencyStats(params) {
    try {
      removeEmptyProperties(params);
      const {
        selectors: currenciesList,
        graph,
        minigraph,
        market_hours: marketHours,
      } = params;
      let mainTicker = null;

      const { cryptocurrency_stats: cryptocurrencyStats = [] } = (
        await new Promise((resolve, reject) => {
          let rejectedCount = 0;

          currenciesList.forEach(async (currency) => {
            try {
              const data = await this.getCryptoChartsData(currency, {
                graph,
                minigraph,
                market_hours: marketHours,
              });
              if (data) {
                mainTicker = currency;
                resolve(data);
              } else {
                throw new Error();
              }
            } catch (e) {
              rejectedCount += 1;
              if (rejectedCount === currenciesList.length) reject();
            }
          });
        })
      );

      return {
        mainTicker,
        stats: Array.isArray(cryptocurrencyStats) ? cryptocurrencyStats.map((stat) => ({
          ...stat,
          last_updated: convertDate(stat.last_updated),
        })) : [{}],
      };
    } catch (e) {
      return {
        error: true,
        mainTicker: null,
        stats: [],
      };
    }
  }

  static async getCryptocurrencyMarketShares(params = {}) {
    try {
      removeEmptyProperties(params);
      const { startDate, endDate } = params;
      const newParams = {
        start_date: startDate,
        end_date: endDate,
      };

      const cacheData = returnAPICachingData(caches, 'marketShareList', newParams);
      if (cacheData) return cacheData;

      const { data } = await RetryAPICall(CryptoTopicsAPI, 'market_share_chart', {
        params: newParams,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      Object.values(data).forEach((item) => {
        item.forEach((value) => {
          value.created_at = convertDate(value.created_at);
        });
      });

      caches.marketShareList.unshift({
        params: newParams,
        expDate: getAPICachingTime(cachingVeryLow),
        response: data,
      });

      return data;
    } catch (e) {
      return null;
    }
  }

  static async getCryptocurrencySummary() {
    try {
      if (caches.summaryData && caches.summaryData.expDate > Date.now()) {
        return caches.summaryData.response || {};
      }

      const { data } = await RetryAPICall(CryptoTopicsAPI, 'summary', {
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      caches.summaryData = {
        expDate: getAPICachingTime(cachingVeryLow),
        response: data,
      };

      return data || {};
    } catch (e) {
      return {};
    }
  }

  static async getCryptocurrencyVolumes(params = {}) {
    try {
      removeEmptyProperties(params);
      const fields = ['per', 'page'];
      const defaultParams = {
        per: 10,
        page: 1,
      };

      fields.forEach((field) => {
        if (!(field in params)) params[field] = defaultParams[field];
      });

      const cacheData = returnAPICachingData(caches, 'volumeChartList', params);
      if (cacheData) return cacheData;

      const { data } = await RetryAPICall(CryptoTopicsAPI, 'volume_chart', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      caches.volumeChartList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: data,
      });

      return data;
    } catch (e) {
      return null;
    }
  }

  static async getCryptocurrencyGainersLosers(params = {}) {
    try {
      removeEmptyProperties(params);
      const fields = ['per', 'page', 'type'];
      const defaultParams = {
        per: 10,
        page: 1,
      };

      fields.forEach((field) => {
        if (!(field in params)) params[field] = defaultParams[field];
      });

      const cacheData = returnAPICachingData(caches, 'gainersLosersChartList', params);
      if (cacheData) return cacheData;

      const { data } = await RetryAPICall(CryptoTopicsAPI, 'gainers_losers_chart', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      caches.gainersLosersChartList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: data,
      });

      return data;
    } catch (e) {
      return e;
    }
  }

  static async getCryptocurrencyFullDetailsData(params = {}) {
    try {
      const cacheData = returnAPICachingData(caches, 'cryptoFullDetailsList', params);
      if (cacheData) return cacheData;

      // Do not add await statement here.
      // It would help to use same cache if 2+ requests are send immediately
      const res = RetryAPICall(CryptoTopicsAPI, 'full_details', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      caches.cryptoFullDetailsList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: res,
      });

      return res;
    } catch (e) {
      return {
        data: [],
      };
    }
  }

  static async getCryptocurrencyFullDetails(params = {}) {
    try {
      removeEmptyProperties(params);
      const fields = ['per', 'page', 'symbol'];
      const defaultParams = {
        page: 1,
        per: 200,
      };

      fields.forEach((field) => {
        if (!(field in params) && field in defaultParams) params[field] = defaultParams[field];
      });

      let mainTicker = null;

      const { data: fullDetails } = await new Promise(async (resolve, reject) => {
        if ('symbol' in params) {
          const res = await this.getCryptocurrencyFullDetailsData(params);
          if (res.data) {
            mainTicker = params.symbol;
            return resolve(res);
          }
          return reject();
        }

        const {
          symbols,
          per,
          page,
          market_hours: marketHours,
        } = params;
        if (!Array.isArray(symbols)) {
          const res = await this.getCryptocurrencyFullDetailsData({
            per,
            page,
          });
          return resolve(res);
        }

        if (!symbols.length) {
          reject();
          return;
        }

        const res = await Promise.all(
          symbols.map((symbol) => (
            this.getCryptocurrencyFullDetailsData({
              per,
              page,
              symbol,
              market_hours: marketHours,
            })
              .then(({ data }) => {
                if (data?.[0]) {
                  mainTicker = symbol;
                  return data?.[0];
                }
              })
              .catch(() => undefined)
          )),
        );
        return resolve({
          data: res.filter((f) => f),
        });
      });

      return {
        fullDetails,
        mainTicker,
      };
    } catch (e) {
      return {
        error: true,
        fullDetails: [{
          created_at: null,
          market_cap_usd: null,
          price_usd: 0,
          volume_usd_24h: null,
          percent_change_24h: 0,
          total_supply: null,
          volume_divide_market_cap: null,
          volume_percent_change_24h: null,
          volume_percentage_of_total: null,
        }],
        mainTicker: null,
      };
    }
  }
}

export default CryptocurrenciesSvc;
