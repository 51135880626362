import axios from 'axios';
import { baseURL } from './api';
import paramsSerializer from '../../helpers/paramsSerializer';

const pathConverterInterceptor = (config) => ({
  ...config,
  baseURL: `${baseURL}internal`,
  url: '',
  params: {
    ...config.params,
    forwarding_path: `${config.baseURL || ''}${config.url ? `/${config.url}` : ''}`,
  },
});

const cryptoBaseURL = '/cryptocurrencies/v1';
const privateCompaniesBaseURL = '/companies_private/v1';
const publicCompaniesBaseURL = '/companies_public/v1';
const companiesBaseURL = '/v2/companies';
const stakeholdersBaseURL = '/v2/stakeholders';
const commoditiesBaseURL = '/commodities/v1';

export const baseInstance = axios.create({
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const cryptoInstance = axios.create({
  baseURL: cryptoBaseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const privateCompaniesInstance = axios.create({
  baseURL: privateCompaniesBaseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const publicCompaniesInstance = axios.create({
  baseURL: publicCompaniesBaseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const companiesInstance = axios.create({
  baseURL: companiesBaseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const stakeholdersInstance = axios.create({
  baseURL: stakeholdersBaseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const commoditiesInstance = axios.create({
  baseURL: commoditiesBaseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

[
  baseInstance,
  cryptoInstance,
  privateCompaniesInstance,
  publicCompaniesInstance,
  companiesInstance,
  stakeholdersInstance,
  commoditiesInstance,
].forEach((instance) => {
  instance.interceptors.request.use(pathConverterInterceptor);
});

export default baseInstance;
