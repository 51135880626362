import {
  SUB_INDUSTRY_TOPIC_CLASS, INDUSTRY_TOPIC_CLASS, CATEGORY_TOPIC_CLASS,
  SECTOR_TOPIC_CLASS, SUB_INDUSTRY_ONLY, INDUSTRY_ONLY, CATEGORY_ONLY,
  SUB_INDUSTRY, TOPIC_CLASS, INDUSTRY, CATEGORY, SECTOR,
  CONTINENT, SUBCONTINENT, COUNTRY, STATE, CITY, AREA, PLACE,
  LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4,
} from './directory/topicTypes';

export const timeFilter = [
  '1d', '1w', '1m', '1y', 'all',
];

export const timeFilterMobile = [
  '1d', '1w', '1m',
];

export const timeFilterTooltip = {
  '1d': {
    tooltip: '1 day',
    text: '1D',
    sentimentCurrent: 'Hour',
    sentimentAvergae: 'Day',
  },
  '1w': {
    tooltip: '1 week',
    text: '1W',
    sentimentCurrent: 'Day',
    sentimentAvergae: 'Week',
  },
  '1m': {
    tooltip: '1 month',
    text: '1M',
    sentimentCurrent: 'Day',
    sentimentAvergae: 'Month',
  },
  '1y': {
    tooltip: '1 year',
    text: '1Y',
    sentimentCurrent: 'Day',
    sentimentAvergae: 'Year',
  },
  all: {
    tooltip: 'All',
    text: 'All',
    sentimentCurrent: 'Day',
    sentimentAvergae: '',
  },
};

export const chartYAxisLabel = [
  {
    id: 1, small_text: '+100%', text: '+100%, Very Positive', type: 'very_positive',
  },
  {
    id: 2, small_text: '+39%', text: '+39%, Positive', type: 'positive', barPercentage: 30.5, isAbsolute: true,
  },
  {
    id: 3, small_text: '+9%', text: '+9%, Neutral', type: 'neutral', barPercentage: 45.5, isAbsolute: true,
  },
  {
    id: 5, small_text: '-9%', text: '-9%, Neutral', type: 'neutral', barPercentage: 54.5, isAbsolute: true,
  },
  {
    id: 6, small_text: '-39%', text: '-39%, Negative', type: 'negative', barPercentage: 69.5, isAbsolute: true,
  },
  {
    id: 7, small_text: '-100%', text: '-100%, Very Negative', type: 'very_negative',
  },
];

export const defaultChartGraphData = {
  type: 'scatter',
  mode: 'lines',
  line: {
    color: '#666666',
    width: 0.98,
    shape: 'spline',
  },
  cliponaxis: false,
  hoverinfo: 'none',
  x: [],
  y: [],
  showlegend: false,
  name: 'sentiment_line',
};

export const defaultChartFillGraphData = {
  x: [],
  y: [],
  fill: 'tonexty',
  fillcolor: 'rgba(120, 191, 101, 0.1)',
  type: 'scatter',
  mode: 'none',
  hoverinfo: 'skip',
  showlegend: false,
};

export const hoverChartMarkerData = {
  x: [],
  y: [],
  type: 'scatter',
  mode: 'markers',
  marker: {
    color: '#78bf65',
    size: 8,
    line: {
      color: 'rgba(122, 191, 104, 0.25)',
      width: 6,
    },
  },
  showlegend: false,
  hoverinfo: 'skip',
};

export const hoverChartLineData = {
  x: [],
  y: [-100, 100],
  type: 'scatter',
  mode: 'lines',
  connectgaps: true,
  line: {
    color: '#78bf65',
    width: 1,
  },
  showlegend: false,
  hoverinfo: 'skip',
};

export const defaultChartLayoutData = {
  autosize: true,
  dragmode: false,
  xaxis: {
    fixedrange: true,
    type: 'date',
    zeroline: true,
    zerolinecolor: '#e0e0e0',
    tickfont: {
      size: 11,
      family: 'Lato',
    },
    mirror: 'ticks',
    linecolor: '#e0e0e0',
    linewidth: 1,
    tickformat: '%e %b',
    cliponaxis: false,
  },
  yaxis: {
    fixedrange: true,
    showgrid: false,
    zerolinecolor: '#e0e0e0',
    showticklabels: false,
    range: [-100, 105],
    dtick: 100,
    tickfont: {
      size: 11,
      family: 'Lato',
    },
    mirror: 'ticks',
    linecolor: '#e0e0e0',
    linewidth: 1,
    ticksuffix: '%',
    cliponaxis: false,
  },
};

export const entityType = {
  summary: 'AssetClass',
  [SUB_INDUSTRY_TOPIC_CLASS]: 'Subindustry',
  [INDUSTRY_TOPIC_CLASS]: 'Industry',
  [CATEGORY_TOPIC_CLASS]: 'StartupCategory',
  [SECTOR_TOPIC_CLASS]: 'Sector',
  [SUB_INDUSTRY_ONLY]: 'Subindustry',
  [INDUSTRY_ONLY]: 'Industry',
  [CATEGORY_ONLY]: 'StartupCategory',
  [SUB_INDUSTRY]: 'Subindustry',
  [TOPIC_CLASS]: 'Topic',
  [INDUSTRY]: 'Industry',
  [CATEGORY]: 'StartupCategory',
  [SECTOR]: 'Sector',

  [CONTINENT]: 'Continent',
  [SUBCONTINENT]: 'SubContinent',
  [COUNTRY]: 'Country',
  [STATE]: 'State',
  [CITY]: 'City',
  [AREA]: 'Area',
  [PLACE]: 'Place',
  [LEVEL_1]: 'Level1',
  [LEVEL_2]: 'Level2',
  [LEVEL_3]: 'Level3',
  [LEVEL_4]: 'Level4',
};
