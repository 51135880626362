import { PureComponent, createRef } from 'react';
import { createPortal } from 'react-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faQuestionCircle as faQuestionCircleSolid } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faQuestionCircle as faQuestionCircleLight } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';

import generateCustomTooltipStyle from './generateCustomTooltipStyle';
import getNextInteger from '../../helpers/getNextInteger';

import withComponentName from '../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class Tooltip extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      tooltipVisible: false,
      active: false,
      isMobile: false,
      initialized: false,
    };
    this.showTimeout = null;
    this.hidingTimeout = null;
    this.originalLeftPosition = null;
    this.tooltipRef = createRef();
    this.tooltipIcon = createRef();
    this.tooltipContent = createRef();
    this.rootElement = null;
    this.tooltipId = getNextInteger();
    this.tooltipContentLocalStyle = null;
  }

  componentDidMount() {
    const { isInfoItemLabel } = this.props;
    if (isInfoItemLabel || isMobile) {
      document.addEventListener('click', this.handleClickOutside, { passive: true });
    }
    this.setState({
      isMobile,
      initialized: true,
    });
    this.rootElement = document.querySelector(':root');
    this.tooltipContentLocalStyle = generateCustomTooltipStyle(this.tooltipId);
    window.addEventListener('resize', this.onResize, { passive: true });
    this.onResize();
  }

  componentWillUnmount() {
    const { isMobile } = this.state;
    const { isInfoItemLabel } = this.props;

    if (isInfoItemLabel || isMobile) {
      document.removeEventListener('click', this.handleClickOutside);
    }
    this.stopTimers();
    this.tooltipContentLocalStyle.remove();
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.setState({
      width: window.innerWidth,
    });
  }

  getStyle() {
    const {
      renderOutside, contentStyle,
      rightAligned, children,
      skipSmall, hideWithOverflow, allowTransformReplace,
    } = this.props;
    const { tooltipVisible } = this.state;
    const visible = Boolean(tooltipVisible && children);

    const tooltipContentStyle = {
      ...contentStyle,
      ...(hideWithOverflow ? {
        visibility: visible ? 'visible' : 'hidden',
      } : {
        display: visible ? 'block' : 'none',
      }),
    };

    if (!renderOutside || !this.tooltipIcon.current || !this.tooltipContent.current) {
      return tooltipContentStyle;
    }

    Object.assign(tooltipContentStyle, {
      bottom: 'auto',
      right: 'auto',
    });

    const { top, left } = this.tooltipIcon.current.getBoundingClientRect();

    tooltipContentStyle.top = `${top + document.documentElement.scrollTop}px`;

    const tooltipContentWidth = this.tooltipContent.current.clientWidth;
    if (
      tooltipContentWidth <= this.tooltipIcon.current.clientWidth
    ) {
      if (!skipSmall) {
        tooltipContentStyle.transform ??= 'translate(0, 0)';
        tooltipContentStyle.left = `${left + Math.floor((this.tooltipIcon.current.clientWidth - tooltipContentWidth) / 2)}px`;
        if (allowTransformReplace) {
          tooltipContentStyle.transform = tooltipContentStyle.transform.replace(/translate\(.+?, (.+?)\)/, 'translate(0, $1)');
        }
      } else if (hideWithOverflow) {
        tooltipContentStyle.visibility = 'hidden';
      } else {
        tooltipContentStyle.display = 'none';
      }
    } else if (rightAligned) {
      tooltipContentStyle.right = `${window.innerWidth - left - this.tooltipIcon.current.clientWidth}px`;
    } else {
      tooltipContentStyle.left = `${left + (this.tooltipIcon.current.clientWidth / 2)}px`;
    }

    return tooltipContentStyle;
  }

  handleClickOutside(e) {
    const { active } = this.state;
    if (((e.target && active) || (e.type === 'click' && !isMobile))
      && this.tooltipRef.current && !this.tooltipRef.current.contains(e.target)
    ) {
      this.hideTooltip();
    }
  }

  showTooltip(e) {
    e?.stopPropagation();
    const {
      delayTooltip,
      isEtoro,
      iconClick,
      isInfoItemLabel,
      dynamicPosition,
    } = this.props;
    const { width } = this.state;
    this.stopTimers();
    this.showTimeout = setTimeout(() => {
      this.setState(({ active, isMobile }) => ({
        tooltipVisible: !(isInfoItemLabel && isMobile),
        active: !isMobile ? active : true,
      }), () => {
        const { tooltipVisible } = this.state;
        if (isEtoro) {
          this.tooltipContent.current.style.removeProperty('width');
          const {
            width: tooltipWidth,
            right: tooltipRight,
            left: tooltipLeft,
          } = this.tooltipContent.current.getBoundingClientRect();
          if ((Math.round(tooltipRight) >= window.innerWidth)) {
            if (window.innerWidth > 320) {
              this.tooltipContent.current.style.width = `${tooltipWidth
                - (2.5 * (tooltipRight - window.innerWidth) + 10)}px`;
            } else {
              this.tooltipContent.current.style.left = `${(window.innerWidth - tooltipRight)}px`;
            }
          } else if (tooltipLeft > -40 && tooltipLeft < -3) {
            if (window.innerWidth < 1023) {
              this.tooltipContent.current.style.width = `${Math.abs(tooltipRight + tooltipLeft) - 10}px`;
            } else if (window.innerWidth > 320) {
              this.tooltipContent.current.style.width = `${Math.abs(((tooltipRight - window.innerWidth))
                + tooltipWidth - tooltipLeft)}px`;
            } else {
              this.tooltipContent.current.style.width = `${((tooltipRight - window.innerWidth))
                + tooltipWidth - tooltipLeft - 40}px`;
            }
          }
          iconClick(tooltipVisible);
        }

        if (isInfoItemLabel) {
          const tooltipLeft = Math.round(this.tooltipContent.current.getBoundingClientRect().left);
          if (tooltipLeft < 50) {
            this.tooltipContent.current.style.left = '93px';
            this.tooltipContent.current.style.setProperty('--left', `${(this.tooltipIcon.current.offsetWidth / 2)}px`);
          }
        }

        if (dynamicPosition) {
          if (
            this.originalLeftPosition !== null
            && this.originalLeftPosition !== this.tooltipContent.current.style.left
          ) {
            return;
          }

          const {
            width: tooltipWidth,
            right: tooltipRight,
            left: tooltipLeft,
          } = this.tooltipContent.current.getBoundingClientRect();

          if (tooltipLeft < 10) {
            this.originalLeftPosition = this.tooltipContent.current.style.left;
            this.tooltipContent.current.style.left = `${parseFloat(this.tooltipContent.current.style.left) - (tooltipLeft - 10)}px`;
            this.rootElement.style.setProperty(
              `--tooltip-caret-left-${this.tooltipId}`,
              `${tooltipWidth / 2 - 6 + (tooltipLeft - 10)}px`,
            );
          } else if (tooltipRight > width - 20) {
            this.originalLeftPosition = this.tooltipContent.current.style.left;
            const rightSpace = width <= 1024 ? 10 : 20;
            this.tooltipContent.current.style.left = `${parseFloat(this.tooltipContent.current.style.left) - (tooltipRight - width + rightSpace)}px`;
            this.rootElement.style.setProperty(
              `--tooltip-caret-left-${this.tooltipId}`,
              `${tooltipWidth / 2 - 6 + (tooltipRight - width + rightSpace)}px`,
            );
          } else {
            this.rootElement.style.removeProperty(
              `--tooltip-caret-left-${this.tooltipId}`,
            );
            if (this.originalLeftPosition !== null) {
              this.tooltipContent.current.style.left = this.originalLeftPosition;
              this.originalLeftPosition = null;
            }
          }
        }
      });
    }, e?.type === 'click' ? 0 : delayTooltip);
  }

  toggleTooltip(e) {
    e?.stopPropagation();
    const {
      isInfoItemLabel,
    } = this.props;
    this.stopTimers();

    this.setState(({ active, isMobile }) => ({
      tooltipVisible: !(isInfoItemLabel && isMobile),
      active: !isMobile ? active : true,
    }), () => {
      if (isInfoItemLabel) {
        const tooltipLeft = Math.round(this.tooltipContent.current.getBoundingClientRect().left);
        if (tooltipLeft < 50) {
          this.tooltipContent.current.style.left = '93px';
          this.tooltipContent.current.style.setProperty('--left', `${(this.tooltipIcon.current.offsetWidth / 2)}px`);
        }
      }
    });
  }

  hideTooltip() {
    const { isEtoro, iconClick } = this.props;
    this.stopTimers();
    this.hidingTimeout = setTimeout(() => {
      this.setState(({ active, isMobile }) => ({
        tooltipVisible: false,
        active: !isMobile ? active : false,
      }), () => {
        if (isEtoro) {
          const { tooltipVisible } = this.state;
          iconClick(tooltipVisible);
        }
        this.rootElement.style.removeProperty(
          `--tooltip-caret-left-${this.tooltipId}`,
        );
        if (this.originalLeftPosition !== null) {
          this.tooltipContent.current.style.left = this.originalLeftPosition;
          this.originalLeftPosition = null;
        }
      });
    }, !isMobile ? 200 : 0);
  }

  stopTimers() {
    clearTimeout(this.showTimeout);
    clearTimeout(this.hidingTimeout);
  }

  stopClickPropagations(e) {
    const { isMobile } = this.state;
    if (!isMobile) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  render() {
    const {
      className,
      children,
      icon,
      disableOnMobile,
      disabled,
      tooltipClassName,
      tooltipIconClassName,
      renderOutside,
      contentStyle,
      rightAligned,
      skipSmall,
      delayTooltip,
      hideWithOverflow,
      stopPropagations,
      iconClick,
      hideIcon,
      isBigScreen,
      newIcon,
      hasBigScreenDesign,
      isEtoro,
      allowTransformReplace,
      isInfoItemLabel,
      dynamicPosition,
      allowTooltipClick,
      firstClickIOSIssue,
      keepMouseLeaveEvent,
      isCentered,
      ...otherProps
    } = this.props;
    const {
      active, isMobile,
      tooltipVisible, initialized,
    } = this.state;

    let clickEvent = () => { };
    if (isInfoItemLabel) {
      clickEvent = this.toggleTooltip;
    } else if (allowTooltipClick) {
      clickEvent = this.showTooltip;
    }

    let events = isMobile ? {
      onClick: () => { this.showTooltip(); },
    } : {
      onClick: clickEvent,
      onMouseEnter: this.showTooltip,
      onMouseLeave: this.hideTooltip,
    };

    if (firstClickIOSIssue && isMobile) {
      events = {
        onClick: this.showTooltip,
      };
    }
    if (disabled || (isMobile && disableOnMobile)) {
      events = keepMouseLeaveEvent ? { onMouseLeave: this.hideTooltip } : {};
    }

    const tooltipClass = cx({
      tooltip: true,
      active,
      touch_device: isMobile,
      [className]: true,
      big_screen: hasBigScreenDesign,
    });

    const tooltipContent = (
      <div
        className={(
          `${cx(
            'tooltip-content',
            `tooltip-content-local-${this.tooltipId}`,
            tooltipClassName,
            {
              'right-aligned': rightAligned,
              big_screen: hasBigScreenDesign,
              'is-centered': isCentered,
            },
          )} tooltip-content`
        )}
        style={this.getStyle()}
        ref={this.tooltipContent}
        {...events}
      >
        {children}
      </div>
    );

    let tooltipContentNode;
    // skipping initial rendering of tooltip, if tooltip should be rendered outside
    // to avoid SSR errors.
    if (!initialized && renderOutside) {
      tooltipContentNode = null;
    } else {
      tooltipContentNode = (
        renderOutside ? (
          createPortal(
            tooltipContent,
            document.body,
          )
        ) : tooltipContent
      );
    }

    return (
      <div
        className={tooltipClass}
        {...otherProps}
        onClick={!otherProps.onClick && stopPropagations ? (
          this.stopClickPropagations
        ) : (
          otherProps.onClick
        )}
        ref={this.tooltipRef}
      >
        <div
          onClick={iconClick}
          {...events}
          className={`${cx('tooltip-icon', { bigScreen: isBigScreen })} tooltip-icon ${tooltipIconClassName}`}
          ref={this.tooltipIcon}
        >
          {newIcon ? (
            <div className={`${cx('tooltip-icon-wrapper-new')} tooltip-icon-wrapper`}>
              <FontAwesomeIcon
                icon={!tooltipVisible ? faQuestionCircleLight : faQuestionCircleSolid}
                className={cx('tooltip-icon')}
              />
            </div>
          ) : icon}
        </div>
        {tooltipContentNode}
      </div>
    );
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  disableOnMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipClassName: PropTypes.string,
  tooltipIconClassName: PropTypes.string,
  renderOutside: PropTypes.bool,
  rightAligned: PropTypes.bool,
  skipSmall: PropTypes.bool,
  hideWithOverflow: PropTypes.bool,
  allowTransformReplace: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  contentStyle: PropTypes.object,
  delayTooltip: PropTypes.number,
  stopPropagations: PropTypes.bool,
  iconClick: PropTypes.func,
  newIcon: PropTypes.bool,
  isEtoro: PropTypes.bool,
  hasBigScreenDesign: PropTypes.bool,
  isInfoItemLabel: PropTypes.bool,
  dynamicPosition: PropTypes.bool,
  allowTooltipClick: PropTypes.bool,
  firstClickIOSIssue: PropTypes.bool,
  keepMouseLeaveEvent: PropTypes.bool,
};

Tooltip.defaultProps = {
  className: '',
  icon: (
    <div className={`${cx('tooltip-icon-wrapper')} tooltip-icon-wrapper`}>
      <FontAwesomeIcon icon={faQuestion} className={cx('tooltip-icon')} />
    </div>
  ),
  disableOnMobile: false,
  disabled: false,
  tooltipClassName: '',
  tooltipIconClassName: '',
  renderOutside: false,
  rightAligned: false,
  skipSmall: false,
  hideWithOverflow: false,
  allowTransformReplace: true,
  contentStyle: {},
  delayTooltip: 0,
  stopPropagations: false,
  iconClick: () => { },
  newIcon: false,
  isEtoro: false,
  hasBigScreenDesign: false,
  isInfoItemLabel: false,
  dynamicPosition: false,
  allowTooltipClick: false,
  firstClickIOSIssue: false,
  keepMouseLeaveEvent: false,
};

export default withComponentName(Tooltip);
