import pick from 'lodash-es/pick';
import moment from 'moment';

import { getFormattedQuery } from './helpers';
import { getUserToken } from './getUserToken';
import { removeEmptyProperties } from './commonHelpers';
import { filterSourceDomains } from './filtersHelpers';
import { getTrendingBarAssetQuery } from './watchlistHelperFunctions';

import { STORIES_LIMIT } from '../data/filters/storyFilters';
import {
  AREA, CITY, CONTINENT,
  COUNTRY, PLACE, STATE,
  SUBCONTINENT,
} from '../data/directory/topicTypes';
import {
  cachingVeryLow, cachingLow, cachingMedium,
  cachingVeryHigh, cachingHigh,
} from '../data/webPageData';

export function getTimePeriodStartDate(timePeriod, maxSearchPeriod) {
  const [count, unit] = timePeriod.split(' ');
  const date = new Date();

  if (Number.isNaN(count)) return date;

  switch (unit) {
    case 'minutes':
      date.setMinutes(date.getMinutes() - count);
      break;
    case 'hours':
      date.setHours(date.getHours() - count);
      break;
    case 'days':
      date.setDate(date.getDate() - count);
      break;
    case 'weeks':
      date.setDate(date.getDate() - count * 7);
      break;
    case 'months':
      date.setMonth(date.getMonth() - count);
      break;
    case 'years':
      date.setFullYear(date.getFullYear() - count);
      break;
    default:
  }

  date.setDate(date.getDate() - 3);
  if (new Date(maxSearchPeriod) > date) {
    date.setDate(date.getDate() + 3);
  }

  return date.toISOString();
}

export function prepareStoryParams(params) {
  let storyParams = {};

  const allowedParams = [
    'query', 'limit', 'categories', 'order_by', 'time_filter',
    'time_period', 'start_datetime', 'end_datetime', 'languages',
    'all_languages', 'relevancy', 'min_score', 'source',
    'premium_only', 'translate_to', 'whitelisted_domain_ids',
    'blacklisted_domain_ids', 'asset_classes', 'asset_classes_with_children',
    'events', 'startup_events_with_children', 'continents',
    'continents_with_children', 'sub_continents', 'sub_continents_with_children',
    'countries', 'countries_with_children', 'states', 'states_with_children',
    'cities', 'cities_with_children', 'areas', 'areas_with_children',
    'places', 'places_with_children', 'with_locations', 'with_sentiments',
    'sentiment_gte', 'sentiment_lte', 'with_entities', 'fold_similar_stories',
    'with_similar_stories_count', 'page_token', 'start_datetime', 'end_datetime',
    'selected_time_filter', 'time_period', 'domains_filtering_mode',
    'only_summaries', 'number_of_summaries', 'summarization_coverage',
    'show_summarization_debug_info', 'sentiment_gte', 'sentiment_lte', 'sentiment_levels',
    'only_with_images', 'include_all_topics', 'fields',
  ];
  const defaultParams = {
    limit: STORIES_LIMIT,
    ...(params.isRefresh && { isRefresh: true }),
    with_sentiments: true,
  };

  allowedParams.forEach((param) => {
    if (param in params) storyParams[param] = params[param];
  });

  Object.entries(defaultParams).forEach(([param, value]) => {
    if (!(param in storyParams)) storyParams[param] = value;
  });

  removeEmptyProperties(storyParams);
  // selected domains
  storyParams = filterSourceDomains(storyParams);

  return storyParams;
}

export function prepareSimilarStoryParams(params) {
  const similarStoryParams = pick(params, [
    'translate_to', 'with_sentiments', 'with_locations', 'with_entities',
    'limit', 'page_token', 'all_languages', 'languages',
  ]);

  removeEmptyProperties(similarStoryParams);

  return similarStoryParams;
}

export const filterSelectedSentimentForStories = ({
  sentiment_filter: type,
  sentiment_categories: category,
  sentiment_down_limit: downLimit,
  sentiment_up_limit: upLimit,
}, hasAccess) => {
  if (!hasAccess) {
    return [];
  }
  if (type === 'range') {
    if (downLimit === -100 && upLimit === 100) return [];
    return ['sentiment_gte', 'sentiment_lte'];
  }
  return (category || []).length === 5 ? [] : ['sentiment_levels'];
};

const LESS_THAN_FIVE_MINUTE = 'lessThanFiveMinute';
const LESS_THAN_DAY = 'lessThanDay';
const LESS_THAN_MONTH = 'lessThanMonth';
const ALL_TIME = 'allTime';

const generateTimeBasedOnFilters = (params, onlySummaries) => {
  const {
    selected_time_filter: timeType, time_filter: timeFilter, time_period: timePeriod,
    start_datetime: startDateTime,
  } = params;

  if (timeType === 'predefined' || timeFilter) {
    if (getUserToken() && !onlySummaries && timeFilter === 'minutes5') {
      return LESS_THAN_FIVE_MINUTE;
    }
    if (['minutes5', 'minutes15', 'hour1', 'hours8', 'day1'].includes(timeFilter)) {
      return LESS_THAN_DAY;
    }
    if (['week1', 'month1'].includes(timeFilter)) {
      return LESS_THAN_MONTH;
    }
  }

  if (timeType === 'time_period' || timePeriod) {
    const startDate = getTimePeriodStartDate(timePeriod);
    if (getUserToken() && !onlySummaries && moment(startDate).isAfter(moment().subtract(5, 'minutes'))) {
      return LESS_THAN_FIVE_MINUTE;
    }
    if (moment(startDate).isAfter(moment().subtract(1, 'days'))) {
      return LESS_THAN_DAY;
    }
    if (moment(startDate).isAfter(moment().subtract(1, 'months'))) {
      return LESS_THAN_MONTH;
    }
  }

  if (timeType === 'custom' || startDateTime) {
    if (getUserToken() && !onlySummaries && moment(startDateTime).isAfter(moment().subtract(5, 'minutes'))) {
      return LESS_THAN_FIVE_MINUTE;
    }
    if (moment(startDateTime).isAfter(moment().subtract(1, 'days'))) {
      return LESS_THAN_DAY;
    }
    if (moment(startDateTime).isAfter(moment().subtract(1, 'months'))) {
      return LESS_THAN_MONTH;
    }
  }
  return ALL_TIME;
};

export const getStoriesCacheType = (params) => {
  const { order_by: orderBy, only_summaries: onlySummaries } = params;

  const orderFilter = ['top', 'read'].includes(orderBy);
  const timeValue = generateTimeBasedOnFilters(params, onlySummaries);

  if (onlySummaries) {
    if (orderFilter && timeValue === LESS_THAN_DAY) {
      return cachingMedium;
    }
    if (orderFilter && timeValue === LESS_THAN_MONTH) {
      return cachingHigh;
    }
    if (orderFilter && timeValue === ALL_TIME) {
      return cachingVeryHigh;
    }
    return cachingLow;
  }

  if (orderFilter && timeValue === LESS_THAN_FIVE_MINUTE) {
    return '';
  }
  if (orderFilter && timeValue === LESS_THAN_DAY) {
    return cachingLow;
  }
  if (orderFilter && timeValue === LESS_THAN_MONTH) {
    return cachingMedium;
  }
  if (orderFilter && timeValue === ALL_TIME) {
    return cachingVeryHigh;
  }
  return cachingVeryLow;
};

export const getStoryParams = ({
  filters,
  isSimpleLayout,
  selectedSentimentTab,
}) => {
  const params = {
    ...filters,
  };

  if (filters.all_languages) {
    delete params.languages;
  }
  if (!isSimpleLayout && selectedSentimentTab !== 'all') {
    params.sentiment_levels = selectedSentimentTab;
  } else {
    delete params.sentiment_levels;
  }

  params.all_languages = Boolean(filters.all_languages);
  params.premium_only = filters.premium_content_only;
  params.fold_similar_stories = filters.group_similar_stories;

  return params;
};

export const getLocationStoryFilters = (selectedLocations) => {
  const typesMapping = new Map([
    [CONTINENT, 'Continent'],
    [SUBCONTINENT, 'SubContinent'],
    [COUNTRY, 'Country'],
    [STATE, 'State'],
    [CITY, 'City'],
    [AREA, 'Area'],
    [PLACE, 'Place'],
  ]);
  const query = selectedLocations.map((location) => (
    getFormattedQuery(
      getTrendingBarAssetQuery(location, typesMapping.get(location.type)),
    )
  )).join(' OR ');

  return { query };
};
