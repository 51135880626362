/**
*
* @param {string} timeZone - timezone identifier
* @return {number} difference of given timezone from UTC in minutes
*/
const getTimezoneOffset = (timeZone = 'UTC') => {
  const now = new Date();

  const utcDate = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(now.toLocaleString('en-US', { timeZone }));

  // difference in minutes
  return (tzDate.getTime() - utcDate.getTime()) / 60_000;
};

export default getTimezoneOffset;
