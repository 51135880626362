import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaughBeam as faLaughBeamLight } from '@fortawesome/pro-light-svg-icons/faLaughBeam';
import { faSmile as faSmileLight } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faMeh as faMehLight } from '@fortawesome/pro-light-svg-icons/faMeh';
import { faFrown as faFrownLight } from '@fortawesome/pro-light-svg-icons/faFrown';
import { faAngry as faAngryLight } from '@fortawesome/pro-light-svg-icons/faAngry';
import { faLaughBeam as faLaughBeamRegular } from '@fortawesome/pro-regular-svg-icons/faLaughBeam';
import { faSmile as faSmileRegular } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faMeh as faMehRegular } from '@fortawesome/pro-regular-svg-icons/faMeh';
import { faFrown as faFrownRegular } from '@fortawesome/pro-regular-svg-icons/faFrown';
import { faAngry as faAngryRegular } from '@fortawesome/pro-regular-svg-icons/faAngry';

import { checkAllAssetsStatus } from './helpers';
import { queryItemWatchlistParser } from './watchlistHelperFunctions';
import { LISTINGS_DEFAULT_MIN_CONFIDENCE } from '../data/environment';
import {
  defaultChartGraphData, defaultChartFillGraphData,
} from '../data/sentimentAnalysis';

export const getPeriodType = (value) => {
  switch (value) {
    case '1d':
      return {
        period: 'hour',
        inDateFormat: 'HH:mm',
        outDateFormat: 'HH a',
      };
    case '1w':
      return {
        period: 'week',
        inDateFormat: 'DD.MM.YYYY:HH:mm',
        outDateFormat: 'YYYY-MM-DD HH:mm',
      };
    case '1m':
      return {
        period: 'day',
        inDateFormat: 'DD.MM.YYYY',
        outDateFormat: 'YYYY-MM-DD',
      };
    case '1y':
      return {
        period: 'year',
        inDateFormat: 'DD.MM.YYYY',
        outDateFormat: 'YYYY-MM-DD',
      };
    case 'all':
      return {
        period: 'all',
        inDateFormat: 'DD.MM.YYYY',
        outDateFormat: 'YYYY-MM-DD',
      };
    default:
      return {
        period: 'day',
        inDateFormat: 'DD.MM.YYYY',
        outDateFormat: 'YYYY-MM-DD',
      };
  }
};

export const getAverageSentimentText = (value, isNewType, useRegularIcons = false) => {
  if (value >= 40 && !isNewType) {
    return {
      value,
      text: 'Very Positive',
      storyText: (
        <>
          <FontAwesomeIcon icon={useRegularIcons ? faLaughBeamRegular : faLaughBeamLight} />
          {`Very Positive +${value}%`}
        </>
      ),
      type: 'very_positive',
      icon: useRegularIcons ? faLaughBeamRegular : faLaughBeamLight,
    };
  }
  if (value >= 10) {
    return {
      value,
      text: 'Positive',
      storyText: (
        <>
          <FontAwesomeIcon icon={useRegularIcons ? faSmileRegular : faSmileLight} />
          {`Positive +${value}%`}
        </>
      ),
      type: 'positive',
      icon: useRegularIcons ? faSmileRegular : faSmileLight,
    };
  }
  if (value >= -9) {
    const text = value > 0 ? `+${value}` : value;
    return {
      value,
      text: 'Neutral',
      storyText: (
        <>
          <FontAwesomeIcon icon={useRegularIcons ? faMehRegular : faMehLight} />
          {`Neutral ${text}%`}
        </>
      ),
      type: 'neutral',
      icon: useRegularIcons ? faMehRegular : faMehLight,
    };
  }
  if (value >= -39 || (isNewType && value >= -100)) {
    return {
      value,
      text: 'Negative',
      storyText: (
        <>
          <FontAwesomeIcon icon={useRegularIcons ? faFrownRegular : faFrownLight} />
          {`Negative ${value}%`}
        </>
      ),
      type: 'negative',
      icon: useRegularIcons ? faFrownRegular : faFrownLight,
    };
  }
  if (value >= -100 && !isNewType) {
    return {
      value,
      text: 'Very Negative',
      storyText: (
        <>
          <FontAwesomeIcon icon={useRegularIcons ? faAngryRegular : faAngryLight} />
          {`Very Negative ${value}%`}
        </>
      ),
      type: 'very_negative',
      icon: useRegularIcons ? faAngryRegular : faAngryLight,
    };
  }
  return {
    value: 0,
    text: 'Neutral',
    storyText: (
      <>
        <FontAwesomeIcon icon={useRegularIcons ? faMehRegular : faMehLight} />
        Neutral 0%
      </>
    ),
    type: 'neutral',
    icon: useRegularIcons ? faMehRegular : faMehLight,
  };
};

export const barPercentageCalculator = (value) => {
  /*
    Calculate average sentiment heat bar
    Range start from -100 to 100(div bar contain width 100%)
    Because of that calculation will be done with X/2
    If point negative it will be done in 0%-50%, If point positive it will be done in 51%-100%
    Like 200 -> X then 100 -> ?(how much)
  */

  /*
    Add 100(each has 50% part to check whole range add another 50%(that is 100))
    divided by 2(as it contain only 50%)
  */
  const activeRange = (Math.abs(value) + 100) / 2;

  if (value > 0) { // Positive
    // For hidding not active range bar it will start from right to left
    return 100 - activeRange;
  }
  if (value < 0) { // Negative
    // For negative no need to subtract from 100
    return activeRange;
  }
  // 0 will always has 50%
  return 50;
};

export const getAverageSentimentPoint = (data = [], useRegularIcons = false) => {
  if (!data.length) {
    return {
      type: 'neutral',
      text: 'Neutral 0%',
      value: 0,
    };
  }

  const totalSum = data.reduce((a, b) => a + b, 0);
  const avgCount = data.filter((f) => f || f === 0);
  const avg = Math.round(totalSum / avgCount.length);
  const avgData = getAverageSentimentText(avg, false, useRegularIcons);

  return avgData;
};

const convertChartDate = (data, { inDateFormat, outDateFormat }) => {
  if (!data) return [];

  return data.map((currentDate) => moment(currentDate, inDateFormat).format(outDateFormat));
};

const convertCurrentDateLabel = (data) => {
  const dateLabel = [];
  let isCurrentDate = false;

  // Getting time from the API is in ISO format
  for (let i = 0; i < data.length; i += 1) {
    const dateTimeISO = moment.parseZone(data[i], 'HH:mm'); // Convert time into UTC
    const dateTimeZone = moment(dateTimeISO.format()); // Convert time into moment object
    // Get user hour(converted from UTC hour to user zone hour)
    const currentHour = dateTimeZone.hour();
    // Use user hour and create today date(and current hour)
    const userTimeZone = moment(dateTimeZone.format('HH:mm'), 'HH:mm');

    if (currentHour === 0) {
      isCurrentDate = true;
    }

    if (isCurrentDate) {
      const mainDate = userTimeZone;
      dateLabel.push(mainDate.format('YYYY-MM-DD HH:mm'));
    } else {
      const mainDate = userTimeZone.subtract(1, 'days');
      dateLabel.push(mainDate.format('YYYY-MM-DD HH:mm'));
    }

    if (currentHour === 23) {
      isCurrentDate = true;
    }
  }
  return dateLabel;
};

const getLastValue = (data) => {
  const filterData = data?.filter((f) => f || f === 0);

  if (filterData?.length) {
    const lastValue = filterData[filterData.length - 1];
    const lastValueObj = getAverageSentimentText(lastValue);
    const avgData = {
      ...lastValueObj,
      text: lastValueObj.value > 0 ? `+${lastValueObj.value}` : lastValueObj.value,
    };
    const barPercentage = barPercentageCalculator(lastValue);

    return {
      ...avgData,
      barPercentage,
    };
  }
  return null;
};

const trimChartData = ({ x, y }) => {
  if (!y?.some((s) => s !== null)) {
    return {
      x: [],
      y: [],
    };
  }
  let firstIndex = 0;
  let lastIndex = y.length;
  for (let i = 0; i < y.length; i += 1) {
    if (y[i] !== null) {
      if (i !== 0) {
        firstIndex = i;
      }
      break;
    }
  }
  for (let i = y.length - 1; i >= 0; i -= 1) {
    if (y[i] !== null) {
      if (i !== y.length - 1) {
        lastIndex = i + 1;
      }
      break;
    }
  }
  return {
    x: x.slice(firstIndex, lastIndex),
    y: y.slice(firstIndex, lastIndex),
  };
};

const trimAllChartData = ({ x, y, confidenceY }) => {
  if (!y.some((s) => s !== null)) {
    return {
      x: [],
      y: [],
      confidenceY: [],
    };
  }
  const newX = [];
  const newY = [];
  const newConfidenceY = [];
  for (let i = 0; i < y.length; i += 1) {
    if (y[i] !== null) {
      newX.push(x[i]);
      newY.push(y[i]);
      newConfidenceY.push(confidenceY[i]);
    }
  }

  return {
    x: newX,
    y: newY,
    confidenceY: newConfidenceY,
  };
};

const calculateConfidenceData = ({ x, y, confidenceY }) => {
  const newX = [];
  const newY = [];
  const newConfidenceX = [];
  const newConfidenceY = [];
  const newConfidence = [];

  let isFirst = false;
  let isLast = false;

  for (let i = 0; i < x.length; i += 1) {
    newX.push(x[i]);
    newConfidenceX.push(x[i]);
    newConfidence.push(confidenceY[i]);
    if (confidenceY[i] <= Number(LISTINGS_DEFAULT_MIN_CONFIDENCE)) {
      if (!isFirst) {
        isFirst = true;
        if (i > 0) {
          newConfidenceY[newConfidenceY.length - 1] = y[i - 1];
        }
      }
      newConfidenceY.push(y[i]);
      newY.push(null);
      if (confidenceY[i + 1] > Number(LISTINGS_DEFAULT_MIN_CONFIDENCE)) {
        isLast = true;
        if (i + 1 < x.length) {
          if (
            confidenceY[i + 2] > Number(LISTINGS_DEFAULT_MIN_CONFIDENCE)
            && confidenceY[i + 3] > Number(LISTINGS_DEFAULT_MIN_CONFIDENCE)
          ) {
            newConfidenceX.push(x[i + 1]);
            newConfidenceY.push(y[i + 1]);
          } else {
            newConfidenceX.push(x[i]);
            newConfidenceY.push(null);

            newX.push(x[i]);
            newY.push(y[i]);
          }
        }
      }
    } else {
      newY.push(y[i]);
      if (isLast) {
        isLast = false;
        newConfidenceY.push(null);
      } else {
        newConfidenceY.push(null);
      }
      if (i + 1 < x.length && confidenceY[i + 1] <= Number(LISTINGS_DEFAULT_MIN_CONFIDENCE)) {
        newX.push(x[i]);
        newY.push(null);
      }
      isFirst = false;
    }
  }

  return {
    newX,
    newY,
    newConfidenceX,
    newConfidenceY,
    newConfidence,
  };
};

const getChartColor = ({ isNewType, type }) => {
  if (isNewType) {
    if (type === 'positive' || type === 'very_positive') {
      return '#78bf65';
    }
    if (type === 'negative' || type === 'very_negative') {
      return '#D0021B';
    }
  } else if (type === 'very_positive') {
    return '#388e3c';
  } else if (type === 'positive') {
    return '#4caf50';
  } else if (type === 'negative') {
    return '#ff6d00';
  } else if (type === 'very_negative') {
    return '#d50000';
  }
  return '#777777';
};

export const generateChartData = (newData) => {
  const chartGraph = {
    ...defaultChartGraphData,
    x: newData.x,
    y: newData.y,
    confidence_y: newData.y,
    line: {
      ...defaultChartGraphData.line,
      color: 'transparent',
      shape: 'linear',
    },
  };
  const chartGraphLine = {
    ...defaultChartGraphData,
    x: newData.lineX,
    y: newData.lineY,
    line: {
      ...defaultChartGraphData.line,
      color: newData.chartColor,
      shape: 'linear',
    },
    hoverinfo: 'skip',
    name: 'sentiment_line_1',
  };
  const chartGraphDash = {
    ...defaultChartGraphData,
    x: newData.confidenceX,
    y: newData.confidenceY,
    line: {
      ...defaultChartGraphData.line,
      color: newData.chartColor,
      dash: '3px',
      shape: 'linear',
    },
    hoverinfo: 'skip',
    name: 'sentiment_line_2',
  };

  return {
    chartColor: newData.chartColor,
    chartGraph,
    chartGraphArr: [chartGraph, {
      ...defaultChartFillGraphData,
      x: newData.x,
      y: Array(newData.x.length).fill(-100),
      fillcolor: newData.chartColor === '#777777' ? '#F9F9F9' : `${newData.chartColor}19`,
    }, chartGraphLine, chartGraphDash],
  };
};

export const generateWLChartData = (dataArr, watchlistTopicArr, extraProps) => {
  if (!dataArr.length) {
    return {
      x: [],
      y: [],
      lineX: [],
      lineY: [],
      confidenceX: [],
      confidenceY: [],
      avgPoint: {},
      lastPointData: null,
    };
  }
  // Exclude all query type topic from the topic array
  const watchlistTopic = watchlistTopicArr.filter((f) => !f.hidden && f.entity_type !== 'Query');
  const isAllCheck = checkAllAssetsStatus(watchlistTopicArr);

  if (isAllCheck) {
    const watchlistData = (dataArr || []).find((f) => f.type === 'Watchlist');
    const newDateLabel = extraProps.period === 'hour'
      ? convertCurrentDateLabel(watchlistData.data.labels)
      : convertChartDate(watchlistData.data.labels, extraProps);

    const { x, y, confidenceY } = trimAllChartData({
      x: newDateLabel, y: watchlistData.data.values, confidenceY: watchlistData.data.confidence,
    });
    const avgPoint = getAverageSentimentPoint(watchlistData.data.values, true);
    const chartColor = getChartColor({ type: avgPoint.type });
    const {
      newX, newY, newConfidenceX, newConfidenceY, newConfidence,
    } = calculateConfidenceData({ x, y, confidenceY });

    return {
      x,
      y,
      lineX: newX,
      lineY: newY,
      confidenceX: newConfidenceX,
      confidenceY: newConfidenceY,
      newConfidence,
      avgPoint,
      lastPointData: getLastValue(watchlistData.data.values),
      chartColor,
    };
  }
  /*
    Creating new array data for saving x,
    y(all topic sum),
    count(exclude null and count all values that will help to calculate avg)
  */
  const data = [];
  for (let i = 0; i < watchlistTopic.length; i += 1) {
    const currentTopic = watchlistTopic[i];
    const { name } = currentTopic.query
      ? queryItemWatchlistParser(currentTopic.query) : currentTopic;
    const foundData = dataArr.find((f) => f.name === name);

    if (foundData) {
      const x = foundData.data.labels;
      const y = foundData.data.values;
      const confidenceY = foundData.data.confidence;
      for (let j = 0; j < x.length; j += 1) {
        // Allow 0 value for the chart(null is not a value)
        const hasValue = y[j] || y[j] === 0;
        const hasConfidence = confidenceY[j] || confidenceY[j] === 0;
        if (!data[j]) {
          data[j] = {
            label: x[j],
            value: y[j] || 0,
            count: hasValue ? 1 : 0,
            confidence: confidenceY[j] || 0,
            confidenceCount: hasConfidence ? 1 : 0,
          };
        } else {
          data[j] = {
            ...data[j],
            value: data[j].value + (y[j] || 0),
            count: data[j].count + (hasValue ? 1 : 0),
            confidence: data[j].confidence + (confidenceY[j] || 0),
            confidenceCount: data[j].confidenceCount + (hasConfidence ? 1 : 0),
          };
        }
      }
    }
  }

  // Calculate avg and rounding number to 2 decimal if it has decimal value
  const x = [];
  const y = [];
  const confidenceY = [];
  for (let i = 0; i < data.length; i += 1) {
    const {
      label, value, count, confidence, confidenceCount,
    } = data[i];
    x.push(label);
    y.push(count ? Number((value / count).toFixed(2)) : null);
    confidenceY.push(confidenceCount ? Number((confidence / confidenceCount).toFixed(2)) : null);
  }

  const newDateLabel = extraProps.period === 'hour'
    ? convertCurrentDateLabel(x)
    : convertChartDate(x, extraProps);

  const {
    x: newX, y: newY, confidenceY: trimConfidenceY,
  } = trimAllChartData({ x: newDateLabel, y, confidenceY });
  const avgPoint = getAverageSentimentPoint(y);

  const chartColor = getChartColor({ type: avgPoint.type });
  const {
    newX: lineX, newY: lineY, newConfidenceX, newConfidenceY, newConfidence,
  } = calculateConfidenceData({ x: newX, y: newY, confidenceY: trimConfidenceY });

  return {
    x: newX,
    y: newY,
    lineX,
    lineY,
    confidenceX: newConfidenceX,
    confidenceY: newConfidenceY,
    newConfidence,
    avgPoint,
    lastPointData: getLastValue(y),
    chartColor,
  };
};

export const dateTooltipFormat = (type) => {
  switch (type) {
    case '1d':
      return 'h:m a';
    case '1w':
      return 'DD MMM YYYY';
    case '1m':
      return 'DD MMM YYYY';
    case '1y':
      return 'MMM YYYY';
    case 'all':
      return 'MMM YYYY';
    default:
      return 'DD MMM YYYY';
  }
};

export const getChartTickFormat = (type, isMiniChart) => {
  switch (type) {
    case '1d':
      return '%I %p';
    case '1w':
      return '%e %b';
    case '1m':
      return '%e %b';
    case '1y':
    case 'all':
      return isMiniChart ? '%b<br>%Y' : '%b %Y';
    default:
      return '%e %b';
  }
};

export const generateTopicChartData = (dataObj, extraProps, useRegularIcons = false) => {
  if (!dataObj) {
    return {
      x: [],
      y: [],
      lineX: [],
      lineY: [],
      confidenceX: [],
      confidenceY: [],
      avgPoint: {},
      lastPointData: null,
    };
  }

  const newDateLabel = extraProps.period === 'hour'
    ? convertCurrentDateLabel(dataObj.labels)
    : convertChartDate(dataObj.labels, extraProps);

  const { x, y, confidenceY } = extraProps.hasConfidence ? trimAllChartData({
    x: newDateLabel, y: dataObj.values, confidenceY: dataObj.confidence,
  }) : trimChartData({ x: newDateLabel, y: dataObj.values });
  const avgPoint = getAverageSentimentText(
    dataObj.average_sentiment || 0,
    extraProps.isNewType,
    useRegularIcons,
  );

  const chartColor = getChartColor({
    type: avgPoint.type,
    isNewType: extraProps.isNewType,
  });

  const {
    newX, newY, newConfidenceX, newConfidenceY, newConfidence,
  } = extraProps.hasConfidence
    ? calculateConfidenceData({ x, y, confidenceY }) : {};

  return {
    x,
    y,
    lineX: newX,
    lineY: newY,
    confidenceX: newConfidenceX,
    confidenceY: newConfidenceY,
    avgPoint,
    newConfidence,
    lastPointData: getLastValue(dataObj.values),
    chartColor,
  };
};

export const generateListingsChartData = (dataArr) => {
  if (!dataArr || !dataArr.length) {
    return {
      x: [],
      y: [],
    };
  }

  const x = [];
  const y = [];
  for (let i = 0; i < dataArr.length; i += 1) {
    const currentList = dataArr[i];
    x.push(currentList.entity_name);
    const sentimentNumber = currentList.sentiment ? currentList.sentiment.replace('%', '') : null;
    y.push(sentimentNumber);
  }

  return { x, y };
};

export const sortBySentiments = ({
  list,
  type = 'positive',
  orderDir = 'ASC',
  length = 5,
}) => (
  list
    .filter((item) => (
      item.sentiment?.length
      && (
        (type === 'positive' && Number(item.sentiment.slice(0, item.sentiment.length - 1)) >= 0)
        || (type === 'negative' && Number(item.sentiment.slice(0, item.sentiment.length - 1)) < 0)
      )
    ))
    .sort((itemA, itemB) => (
      (itemA.sentiment.slice(0, itemA.sentiment.length - 1)
      - itemB.sentiment.slice(0, itemB.sentiment.length - 1)) * (orderDir === 'ASC' ? 1 : -1)
    ))
    .slice(0, length)
);

export const getAverageSentimentStoryText = (value, isThreeType, useRegularIcons = false) => {
  if (value >= 85 && !isThreeType) {
    return {
      value,
      type: 'very_positive',
      icon: useRegularIcons ? faLaughBeamRegular : faLaughBeamLight,
      text: 'Very Positive',
    };
  }
  if (value >= 20) {
    return {
      value,
      type: 'positive',
      icon: useRegularIcons ? faSmileRegular : faSmileLight,
      text: 'Positive',
    };
  }
  if (value >= -19) {
    return {
      value,
      type: 'neutral',
      icon: useRegularIcons ? faMehRegular : faMehLight,
      text: 'Neutral',
    };
  }
  if (value >= -84 || (isThreeType && value >= -100)) {
    return {
      value,
      type: 'negative',
      icon: useRegularIcons ? faFrownRegular : faFrownLight,
      text: 'Negative',
    };
  }
  if (value >= -100) {
    return {
      value,
      type: 'very_negative',
      icon: useRegularIcons ? faAngryRegular : faAngryLight,
      text: 'Very Negative',
    };
  }
  return {
    value: 0,
    type: 'neutral',
    icon: useRegularIcons ? faMehRegular : faMehLight,
    text: 'Neutral',
  };
};
