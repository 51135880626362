import axios from 'axios';
import { returnAPICachingHeader } from '../../helpers/helpers';
import getUserToken from '../../helpers/getUserToken';
import isViewInIframe from '../../helpers/isViewInIframe';
import paramsSerializer from '../../helpers/paramsSerializer';
import { cachingVeryHigh } from '../../data/webPageData';

let origin;
try {
  origin = window.location.origin.replace('cityfalcon.ai', 'cityfalcon.com');
} catch {
  origin = '';
}

export const baseURL = `${origin}/webapi/v1/`;

export const params = {
  ...(!isViewInIframe() && getUserToken() && { api_key: getUserToken() }),
};

export const instance = axios.create({
  baseURL,
  params,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const locationInstance = axios.create({
  baseURL: `${baseURL}locations`,
  headers: {
    'Cache-control': returnAPICachingHeader(cachingVeryHigh),
  },
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const dcscInstance = axios.create({
  baseURL: `${baseURL}levels`,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const chatbotInstance = axios.create({
  baseURL: `${baseURL}intents/web_chat_endpoint`,
  headers: {
    'Cache-control': returnAPICachingHeader(cachingVeryHigh),
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  params,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export const APIWithoutToken = axios.create({
  baseURL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
});

export default instance;
