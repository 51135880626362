import isEqual from 'lodash-es/isEqual';

import { companiesInstance as CompaniesAPI } from './topicsApi';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { APIRecursiveCall, RetryAPICall } from '../../helpers/apiHelpers';
import mergeDeep from '../../helpers/mergeDeep';
import { cachingHigh, cachingNewsVeryHigh, cachingNonNewsLow } from '../../data/webPageData';

const caches = {
  types: [],
  typesESG: [],
  presentations: [],
  presentationsESG: [],
};

export default class PresentationsSvc {
  static async getPresentationsTypes(params = {}, extraProps) {
    const { etoro } = extraProps || {};
    const cachedTypes = caches.types.find((cache) => isEqual(cache.params, params));
    if (cachedTypes && cachedTypes.expDate > Date.now()) {
      return cachedTypes.response;
    }

    try {
      const cacheType = etoro ? cachingNewsVeryHigh : cachingHigh;
      const res = await RetryAPICall(CompaniesAPI, 'presentations/types', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cacheType),
        },
      });

      caches.types.unshift({
        params,
        expDate: getAPICachingTime(cacheType),
        response: res,
      });

      return res;
    } catch {
      return {
        data: [],
      };
    }
  }

  static async getPresentationsEsgTypes(params = {}) {
    const cachedTypesESG = caches.typesESG.find((cache) => isEqual(cache.params, params));
    if (cachedTypesESG && cachedTypesESG.expDate > Date.now()) {
      return cachedTypesESG.response;
    }

    try {
      const res = await RetryAPICall(CompaniesAPI, 'presentations/types/esg', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
      });

      caches.typesESG.unshift({
        params,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch {
      return {
        data: [],
      };
    }
  }

  static async getPresentationsData(params = {}, extraProps) {
    const { etoro } = extraProps || {};
    if (params.isRefresh) {
      caches.presentations = caches.presentations
        .filter(({ params: innerParams }) => innerParams.identifiers !== params.identifiers);
    }
    const cachedData = caches.presentations.find((cache) => isEqual(cache.params, params));
    if (cachedData && cachedData.expDate > Date.now()) {
      return cachedData.response;
    }

    // Splitting the identifier string into chunks of 65 items
    // to avoid too long URLs
    if (typeof params?.identifiers === 'string' && params.identifiers.length > 2000) {
      const identifiers = params.identifiers.split(',');
      const chunkSize = 65;
      return Promise.all(
        Array.from({ length: Math.ceil(identifiers.length / chunkSize) }).map((_, i) => (
          identifiers.slice(i * chunkSize, i * chunkSize + chunkSize).join(',')
        )).map((chunk) => (
          this.getPresentationsData({ ...params, identifiers: chunk }, extraProps)
            .catch(() => null)
        )),
      ).then((responses) => {
        const data = responses.filter(Boolean).reduce((acc, { data }) => mergeDeep(acc, data), []);
        return { data };
      });
    }

    const cacheType = etoro ? cachingNonNewsLow : cachingHigh;
    try {
      const res = await APIRecursiveCall(CompaniesAPI, 'presentations', {
        params: {
          ...params,
          skipSorting: !!etoro,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cacheType, etoro ? 1800 : 900),
        },
      });

      caches.presentations.unshift({
        params,
        expDate: getAPICachingTime(cacheType),
        response: res,
      });

      return res;
    } catch {
      return {
        error: true,
        data: [],
      };
    }
  }

  static async getPresentationsEsgData(params = {}) {
    if (params.isRefresh) {
      caches.presentationsESG = caches.presentationsESG
        .filter(({ params: innerParams }) => innerParams.identifiers !== params.identifiers);
    }
    const cachedData = caches.presentationsESG.find((cache) => isEqual(cache.params, params));
    if (cachedData && cachedData.expDate > Date.now()) {
      return cachedData.response;
    }

    // Splitting the identifier string into chunks of 65 items
    // to avoid too long URLs
    if (typeof params?.identifiers === 'string' && params.identifiers.length > 2000) {
      const identifiers = params.identifiers.split(',');
      const chunkSize = 65;
      return Promise.all(
        Array.from({ length: Math.ceil(identifiers.length / chunkSize) }).map((_, i) => (
          identifiers.slice(i * chunkSize, i * chunkSize + chunkSize).join(',')
        )).map((chunk) => (
          this.getPresentationsEsgData({ ...params, identifiers: chunk })
            .catch(() => null)
        )),
      ).then((responses) => {
        const data = responses.filter(Boolean).reduce((acc, { data }) => mergeDeep(acc, data), []);
        return { data };
      });
    }

    try {
      const res = await RetryAPICall(CompaniesAPI, 'presentations/esg', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
      });

      caches.presentationsESG.unshift({
        params,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch {
      return {
        data: [],
      };
    }
  }
}
