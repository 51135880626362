const paramsSerializer = (params) => {
  if (!(params instanceof Object)) {
    return '';
  }
  const { skipSorting = false, ...urlParams } = params;

  if (skipSorting) {
    return new URLSearchParams(urlParams).toString();
  }

  const searchParams = new URLSearchParams();
  Object.keys(urlParams)
    .sort()
    .forEach((key) => {
      if (typeof urlParams[key] !== 'undefined') {
        searchParams.set(key, urlParams[key]);
      }
    });

  return searchParams.toString();
};

export default paramsSerializer;
