export function removeDuplicates(array, field = '') {
  const uniqueValues = new Set();

  return array.filter((v) => {
    if (uniqueValues.has(v[field])) {
      return false;
    }

    uniqueValues.add(v[field]);
    return true;
  });
}

export default removeDuplicates;
