import moment from 'moment';
import axios from 'axios';

import { APIWithoutToken } from './api';
import StoriesSvc from '../StoriesSvc';

import { STORIES_LIMIT } from '../../data/filters/storyFilters';
import { getAPICachingTime, returnAPICachingData, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingNewsLow, cachingNonNewsLow, cachingNewsVeryHigh } from '../../data/webPageData';
import { APIRecursiveCall } from '../../helpers/apiHelpers';

const caches = {
  storiesList: [],
};

// endpoint for RBI Internal data
export const RBIInternalLoad = (query) => StoriesSvc.getStories({
  query,
  source: 'rbi',
  limit: STORIES_LIMIT,
  all_languages: true,
  order_by: 'latest',
  time_filter: 'month1',
  min_score: 5,
  categories: 'mp,op,r,i,e',
});

export const storiesLoadAPIData = (query, params, earliestSearchDate) => (
  StoriesSvc.getStories({
    ...params,
    query,
  }, earliestSearchDate)
);

export const storiesClientsLoad = async (params, isSentimentStory) => {
  try {
    const cacheData = returnAPICachingData(caches, 'storiesList', params);
    if (cacheData) return cacheData;

    const { end_datetime: endDateTime } = params;
    let cacheType;
    if (isSentimentStory) {
      if (moment(endDateTime).isAfter(moment().utcOffset(0, true).subtract(1, 'days'))) {
        cacheType = cachingNewsLow;
      } else {
        cacheType = moment(endDateTime).isAfter(moment().utcOffset(0, true).subtract(2, 'days'))
          ? cachingNonNewsLow : cachingNewsVeryHigh;
      }
    } else {
      cacheType = cachingNewsLow;
    }

    const {
      data: {
        stories,
        next_page_token: nextPageToken,
        expanded_summaries: summaries = [],
        summaries_key: summariesKey = null,
      },
      error,
    } = await APIRecursiveCall(APIWithoutToken, 'stories', {
      params,
      headers: {
        'Cache-control': returnAPICachingHeader(cacheType, 1800),
      },
    });

    if (error) {
      return {
        error: true,
        stories: [],
        summaries: [],
        nextPageToken: 'EOD',
        summariesKey: null,
        cancelled: false,
      };
    }

    caches.storiesList.unshift({
      params,
      expDate: getAPICachingTime(cacheType),
      response: {
        nextPageToken,
        stories,
        summaries,
        summariesKey,
      },
    });

    return {
      nextPageToken,
      stories,
      summaries,
      summariesKey,
    };
  } catch (e) {
    return {
      error: true,
      stories: [],
      summaries: [],
      nextPageToken: 'EOD',
      summariesKey: null,
      cancelled: axios.isCancel(e),
    };
  }
};
