import moment from 'moment';
import axios, { isAxiosError } from 'axios';
import { captureException } from '@sentry/react';
import isEqual from 'lodash-es/isEqual';

import API, { APIWithoutToken } from './dbServices/api';
import { getCurationActions } from './dbServices/curationSvc';

import { EARLIEST_DATE_RANGE_ERR, EARLIEST_DAY_ERROR } from '../data/filters/filterConstants';
import { SILVER } from '../data/permissions';
import {
  cachingLow, cachingMedium, cachingHigh, cachingVeryHigh,
} from '../data/webPageData';

import ExternalSharingStore from '../store/ExternalSharingStore';

import { validateEarliestSearchDate } from '../helpers/timeFilterHelpers';
import { prepareStoryParams, prepareSimilarStoryParams, getStoriesCacheType } from '../helpers/storyHelpers';
import {
  getAPICachingTime,
  returnAPICachingData,
  returnAPICachingHeader,
} from '../helpers/helpers';
import { copy } from '../helpers/copy';
import { RetryAPICall } from '../helpers/apiHelpers';
import { STORIES_LIMIT } from '../data/filters/storyFilters';

const caches = {
  storiesList: [],
  summariesList: [],
};

export default class StoriesSvc {
  static async isBasicUser() {
    const accessLevels = await ExternalSharingStore.getAccessLevels();
    return Array.isArray(accessLevels) && !accessLevels.includes(SILVER);
  }

  static async getStoriesData(storiesParams, extraProps) {
    const { isRefresh, ...params } = storiesParams;
    const { cancelToken, cachingType, callCurationActions } = extraProps || {};

    if (isRefresh) {
      caches.storiesList = caches.storiesList
        .filter(({ params: innerParams }) => isEqual(innerParams, params));
    }

    const cacheData = returnAPICachingData(caches, 'storiesList', params);
    if (cacheData && !isRefresh) return cacheData;

    let cacheType;
    if ((params.summaries_key && caches[params.summaries_key]) || cachingType) {
      cacheType = cachingType || caches[params.summaries_key];
    } else {
      cacheType = getStoriesCacheType(params);
    }

    try {
      // Using api token instead of group token for curator users story requests
      // To get curator data in response
      const { data } = await (ExternalSharingStore.isCurationActive ? API : APIWithoutToken).get('stories', {
        params: {
          ...params,
          token: !(await this.isBasicUser()) && !ExternalSharingStore.isCurationActive
            ? await ExternalSharingStore.getGroupToken()
            : undefined,
        },
        cancelToken,
        ...(cacheType && {
          headers: {
            'Cache-control': returnAPICachingHeader(cacheType),
          },
        }),
      });

      if (callCurationActions && data?.stories?.length) {
        const storiesId = data.stories.map(({ id }) => id).join(',');
        const { user_actions: userActions } = await getCurationActions({ story_ids: storiesId });
        data.stories = data.stories
          .map((obj) => {
            const foundAction = (userActions || []).find((f) => f.story_id === obj.id);
            return {
              ...obj,
              ...foundAction,
            };
          })
          .filter((f) => !f.mark_as_irrelevant && !f.hide);
      }

      if (data?.summaries_key) {
        caches[data.summaries_key] = cacheType;
      }

      if (cacheType) {
        caches.storiesList.unshift({
          params,
          expDate: getAPICachingTime(cacheType),
          response: data,
        });
      }

      return {
        ...data,
        notAllowed: false,
        isRefresh: isRefresh ?? false,
      };
    } catch (e) {
      // capture error if not cancelled or 424 status
      if (!axios.isCancel(e) && e?.response?.status !== 424) {
        if (isAxiosError(e)) {
          captureException(e.toJSON());
        } else {
          captureException(e);
        }
      }

      return {
        summaryRecall: e?.response?.status === 424,
        error: true,
        cancelled: axios.isCancel(e),
        stories: [],
        summaries: [],
        notAllowed: e?.response?.status === 403,
        isRefresh: false,
      };
    }
  }

  static async getStories(params = {}, earliestSearchDate, extraProps) {
    // return empty stories obj if no lang or categories elected in filters
    const validDateRange = validateEarliestSearchDate(
      earliestSearchDate, params.start_datetime, params.end_datetime,
    );

    if (
      !params.categories
      || (!params.all_languages && !params.languages)
      || (params.selected_time_filter === 'custom' && validDateRange === EARLIEST_DATE_RANGE_ERR)
    ) {
      return {
        nextPageToken: 'EOD',
        stories: [],
        summaries: [],
        summariesKey: null,
      };
    }

    const paramsCopy = {
      ...params,
      with_sentiments: true,
    };

    // change start time to the earliest search date in case user has diff in 24h
    if (validDateRange === EARLIEST_DAY_ERROR && paramsCopy.selected_time_filter === 'custom') {
      paramsCopy.start_datetime = moment(earliestSearchDate).toISOString(true);
    }
    const storiesParams = prepareStoryParams(paramsCopy);

    try {
      const response = await this.getStoriesData(
        storiesParams,
        extraProps,
      );
      const {
        stories,
        summaries = [],
        summaries_key: summariesKey = null,
        notAllowed,
        error,
        cancelled,
      } = response;

      // If response includes error, stop further processing and return as it
      if (error) {
        return {
          nextPageToken: 'EOD',
          stories,
          summaries,
          notAllowed,
          summariesKey,
          cancelled,
          error,
        };
      }

      let nextPageToken = response.next_page_token;

      // This trick was done to fix recommended stories loading issue
      // Manually setting page token to EOD if response has few stories
      // To let the application know that this was the last set,
      // and he could start showing recommended stories
      if (
        storiesParams.limit === STORIES_LIMIT
        && stories.length < 6
        && extraProps?.skipSmallList
      ) {
        nextPageToken = 'EOD';
      }

      return {
        nextPageToken,
        stories,
        summaries,
        summariesKey,
        notAllowed,
      };
    } catch (e) {
      return {
        nextPageToken: 'EOD',
        stories: [],
        summaries: [],
        notAllowed: e?.response?.status === 403,
        summariesKey: null,
        cancelled: axios.isCancel(e),
      };
    }
  }

  static async getSummaries(summariesKey, cachingType, isRefresh) {
    try {
      const data = await this.getStoriesData({
        only_summaries: true,
        summaries_key: summariesKey,
        isRefresh,
      }, { cachingType });

      return data;
    } catch (e) {
      console.warn(e.message);
      return {
        error: true,
        summaries: [],
        next_page_token: '',
        summaryRecall: e?.response?.status === 424,
      };
    }
  }

  static waitSummaries(summariesKey, cachingType, isRefresh) {
    let summariesFetchingTimer;
    let currentSummriesKey = summariesKey;

    return {
      promise: new Promise((resolve, reject) => {
        const requestStartPoint = Date.now();

        summariesFetchingTimer = setTimeout(async function getSummaries() {
          try {
            const {
              summaries,
              summaries_key: newKey,
              summarization_error: errorMessage = null,
              error,
              summaryRecall,
            } = await StoriesSvc.getSummaries(currentSummriesKey, cachingType, isRefresh);

            if (!summaryRecall && (errorMessage || error)) {
              console.warn(errorMessage);
              resolve([]);
              return;
            }

            if (!summaryRecall && summaries) {
              resolve(Array.isArray(summaries) ? summaries : []);
            } else {
              // if more than 60 seconds no response from server
              if ((Date.now() - requestStartPoint) >= 1000 * 60 /* 60 seconds */) {
                resolve([]);
                return;
              }

              if (newKey) {
                currentSummriesKey = newKey;
              }
              summariesFetchingTimer = setTimeout(getSummaries, 700);
            }
          } catch (e) {
            reject(e);
          }
        });
      }),
      cancel() {
        clearTimeout(summariesFetchingTimer);
      },
    };
  }

  static async getSingleStory(uuid, options = {}) {
    try {
      const allowedParams = [
        'translate_to', 'with_entities', 'with_sentiments',
      ];
      const formattedUuid = uuid.match(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)?.[0];

      if (!formattedUuid) return null;

      const params = {};
      Object.keys(options).forEach((param) => {
        if (allowedParams.includes(param)) {
          params[param] = options[param];
        }
      });

      const key = `single_story_${formattedUuid}`;

      // passing with_sentiments as true for all API calls for
      // fetching labels
      params.with_sentiments = true;

      const cacheData = returnAPICachingData(caches, key, params);
      if (cacheData) return cacheData;

      // Using api token instead of group token for curator users story requests
      // To get curator data in response
      const { data } = await RetryAPICall(ExternalSharingStore.isCurationActive ? API : APIWithoutToken, `stories/${formattedUuid}`, {
        params: {
          ...params,
          token: !(await this.isBasicUser()) && !ExternalSharingStore.isCurationActive
            ? await ExternalSharingStore.getGroupToken()
            : undefined,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      if (!caches[key]) {
        caches[key] = [];
      }

      caches[key].unshift({
        params,
        expDate: getAPICachingTime(cachingVeryHigh),
        response: data,
      });

      return copy(data);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  static async getSimilarStoriesDirectly(storie, storyFilters = {}) {
    const { uuid, publishTime } = storie || {};
    try {
      const formattedUuid = (uuid || '').match(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)?.[0];
      if (!formattedUuid) {
        return {
          next_page_token: 'EOD',
          origin_story_uuid: formattedUuid,
          stories: [],
        };
      }

      const key = `summaries_${uuid}`;

      const params = prepareSimilarStoryParams(storyFilters);
      const cacheData = returnAPICachingData(caches, key, params);
      if (cacheData) return cacheData;

      let cacheType;
      if (moment(publishTime).isAfter(moment().subtract(1, 'hours'))) {
        cacheType = cachingLow;
      } else if (moment(publishTime).isAfter(moment().subtract(1, 'days'))) {
        cacheType = cachingMedium;
      } else if (moment(publishTime).isAfter(moment().subtract(1, 'months'))) {
        cacheType = cachingHigh;
      } else {
        cacheType = cachingVeryHigh;
      }

      // Using api token instead of group token for curator users story requests
      // To get curator data in response
      const { data } = await (ExternalSharingStore.isCurationActive ? API : APIWithoutToken).get(`stories/${formattedUuid}/similars`, {
        params: {
          ...params,
          token: !(await this.isBasicUser()) && !ExternalSharingStore.isCurationActive
            ? await ExternalSharingStore.getGroupToken()
            : undefined,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cacheType),
        },
      });

      const returnData = data[0] || {
        next_page_token: 'EOD',
        origin_story_uuid: formattedUuid,
        stories: [],
      };

      if (!caches[key]) {
        caches[key] = [];
      }

      caches[key].unshift({
        params,
        expDate: getAPICachingTime(cacheType),
        response: returnData,
      });

      return returnData;
    } catch (e) {
      console.warn(e);
      return {
        next_page_token: 'EOD',
        origin_story_uuid: uuid,
        stories: [],
      };
    }
  }

  static filterStories(stories, filters) {
    try {
      const {
        required_source: { registrationRequired = true, paywall = true } = {},
      } = filters || { required_source: {} };

      if (
        registrationRequired
        && paywall
      ) return stories;

      return stories.filter((item) => (
        item.registrationRequired || item.paywall ? (
          (registrationRequired && item.registrationRequired)
          || (paywall && item.paywall)
        ) : true
      ));
    } catch {
      return stories;
    }
  }

  static async getTranslatedStory(uuid, lang, cancelToken) {
    const key = `single_story_${uuid}_${lang}`;

    if (caches[key] && caches[key].response && caches[key]?.expDate > Date.now()) {
      return caches[key].response;
    }

    try {
      // Using api token instead of group token for curator users story requests
      // To get curator data in response
      const res = await RetryAPICall(ExternalSharingStore.isCurationActive ? API : APIWithoutToken, `stories/${uuid}/translation`, {
        cancelToken,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
        params: {
          translate_to: lang,
          token: !(await this.isBasicUser()) && !ExternalSharingStore.isCurationActive
            ? await ExternalSharingStore.getGroupToken()
            : undefined,
        },
      }, 1, 20000, 5);

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: res,
      };

      return res;
    } catch (e) {
      return {
        error: true,
        cancelled: axios.isCancel(e),
      };
    }
  }
}

StoriesSvc.similarStoriesRequestsData = [];
StoriesSvc.similarStoriesTimer = 0;
