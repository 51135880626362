import { PureComponent, createRef } from 'react';
import isEqual from 'lodash-es/isEqual';
import PropTypes from 'prop-types';

import Preloader from '../Preloader';

import withComponentName from '../../decorators/withComponentName';

import { loadScript } from '../../utils';

import { copy } from '../../helpers/copy';

export class Plot extends PureComponent {
  static initializationState = null;

  static initializePlotly() {
    if (this.initializationState !== null) {
      return;
    }

    this.initializationState = new Promise((resolve) => {
      if (('Plotly' in window)) {
        resolve();
        return;
      }

      window.Plotly = null;
      const plotScript = loadScript('https://cdn.plot.ly/plotly-basic-2.14.0.min.js', true);
      document.body.appendChild(plotScript);

      Object.defineProperty(window, 'Plotly', {
        set: (v) => {
          Object.defineProperty(window, 'Plotly', {
            value: v,
          });
          resolve();
        },
        configurable: true,
      });
    });
  }

  constructor(props) {
    super(props);

    this.plot = createRef();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    Plot.initializePlotly();

    if (window.Plotly) {
      this.renderPlot();
    } else {
      Plot.initializationState.then(() => {
        this.renderPlot();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      layout,
      config,
      displayModeBar,
      onClick,
      onHover,
      onRelayouting,
    } = this.props;
    const { current: plot } = this.plot;

    if (
      window.Plotly
      && (
        !isEqual(prevProps.data, data)
        || !isEqual(prevProps.layout, layout)
        || !isEqual(prevProps.config, config)
        || prevProps.displayModeBar !== displayModeBar
      )
    ) {
      window.Plotly.react(plot, data, copy(layout), { displayModeBar, ...config });
    }

    if (!plot) return;

    if (prevProps.onClick !== onClick) {
      if (prevProps.onClick) {
        plot.removeListener?.('plotly_click', prevProps.onClick);
      }
      if (onClick) {
        plot.on?.('plotly_click', onClick);
      }
    }

    if (prevProps.onHover !== onHover) {
      if (prevProps.onHover) {
        plot.removeListener?.('plotly_hover', prevProps.onHover);
      }
      if (onHover) {
        plot.on?.('plotly_hover', onHover);
      }
    }

    if (prevProps.onRelayouting !== onRelayouting) {
      if (prevProps.onRelayouting) {
        plot.removeListener?.('plotly_relayouting', prevProps.onRelayouting);
      }
      if (onRelayouting) {
        plot.on?.('plotly_relayouting', onRelayouting);
      }
    }
  }

  renderPlot() {
    const {
      data,
      layout,
      config,
      onClick,
      onHover,
      onRelayouting,
      onLoad,
      displayModeBar,
    } = this.props;
    const { current: plot } = this.plot;

    this.setState({
      loaded: true,
    });

    window.Plotly.newPlot(plot, data, copy(layout), { displayModeBar, ...config });

    if (onClick) {
      plot.on?.('plotly_click', onClick);
    }
    if (onHover) {
      plot.on?.('plotly_hover', onHover);
    }
    if (onRelayouting) {
      plot.on?.('plotly_relayouting', onRelayouting);
    }
    if (onLoad) {
      onLoad();
    }
  }

  render() {
    const { loaded } = this.state;
    const { hideLoader, loaderClassname = '', chatbot } = this.props;

    return (
      <>
        {!loaded && !hideLoader && (
          <Preloader
            loading
            static={!chatbot}
            centered
            transform={chatbot}
            className={loaderClassname}
          />
        )}
        <div ref={this.plot} />
      </>
    );
  }
}

Plot.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  layout: PropTypes.object.isRequired,
  config: PropTypes.object,
  displayModeBar: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onRelayouting: PropTypes.func,
  onLoad: PropTypes.func,
  hideLoader: PropTypes.bool,
};

Plot.defaultProps = {
  config: {},
  displayModeBar: false,
  onClick: null,
  onHover: null,
  onRelayouting: null,
  onLoad: null,
  hideLoader: false,
};

export default withComponentName(Plot);
