import { APIWithoutToken } from './api';

const cache = {
  stockExchangeMarketHoursMap: new Map(),
};

class StockExchangesSvc {
  /**
   * @param {number} id - ID of stock exchange
   *
   */
  static getStockExchangeMarketHours(id) {
    if (!id) {
      return null;
    }

    const weekDayMap = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    if (cache.stockExchangeMarketHoursMap.has(id)) {
      return cache.stockExchangeMarketHoursMap.get(id);
    }

    const response = APIWithoutToken.get('stock_exchanges/market_hours', {
      params: {
        stock_exchange_id: id,
      },
    }).then(({ data }) => ({
      id: data.id,
      tradingDays: data.trading_days?.map((day) => weekDayMap[day.toLowerCase()]) ?? [],
      openingTime: data.opening_time,
      closingTime: data.closing_time,
      timezone: data.timezone,
    }), () => null);

    cache.stockExchangeMarketHoursMap.set(
      id,
      response,
    );

    return response;
  }
}

export default StockExchangesSvc;
