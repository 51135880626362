import orderBy from 'lodash-es/orderBy';

import CryptocurrenciesSvc from '../services/dbServices/CryptocurrenciesSvc';
import PublicCompaniesSvc from '../services/dbServices/PublicCompaniesSvc';
import CompaniesSvc from '../services/dbServices/CompaniesSvc';
import TopicsSvc from '../services/TopicsSvc';

import {
  CRYPTOCURRENCIES, PUBLIC_COMPANIES, COMMODITIES, INDICES, FOREIGN_EXCHANGE, EXCHANGE_TRADED_FUNDS,
} from '../data/directory/topic_classes/topicClassTypes';
import { removeDuplicates } from './removeDuplicates';

export const getActivePriorityTicker = (tickers = []) => {
  const newTicker = orderBy(((tickers || [])
    .filter((f) => f.default) || []), 'priority', 'asc')
    .map((obj) => ({
      ...obj,
      id: obj.full_name || obj.name,
      content: (obj.full_name || obj.name)?.replace(/global/ig, '')?.replaceAll('_', ' ')?.trim(),
    }));

  const newTickerUnique = removeDuplicates(newTicker, 'id');

  return newTickerUnique;
};

export const convertFullDetails = (data) => ({
  ...data,
  lastUpdated: (
    (data.created_at && new Date(data.created_at * 1000))
    || (data.updated_at && new Date(data.updated_at * 1000))
  ),
  marketCap: data.market_cap_usd || data.market_cap,
  price: data.price_usd || data.price || data.latest_price,
  volumeUsd: data.volume_usd_24h || data.volume_24h,
  percentChange: data.percent_change_24h,
  totalSupply: data.total_supply,
  volumeDivideMarketCap: data.volume_divide_market_cap,
  volumePercentChange: data.volume_percent_change_24h,
  volumePercentageOfTotal: data.volume_percentage_of_total,
});

export const fetchTopicFullDetails = async (assetType, params) => {
  let fullDetails = null;
  let error;

  switch (assetType) {
    case CRYPTOCURRENCIES:
      ({
        fullDetails: [fullDetails], error,
      } = await CryptocurrenciesSvc.getCryptocurrencyFullDetails(params));
      break;
    case PUBLIC_COMPANIES:
    case EXCHANGE_TRADED_FUNDS:
      ({
        fullDetails: [fullDetails], error,
      } = await PublicCompaniesSvc.getPublicCompaniesFullDetails(params));
      break;
    case COMMODITIES: {
      ({
        fullDetails: [fullDetails], error,
      } = await CompaniesSvc.getCommoditiesFullData(params));
      break;
    }
    case INDICES: {
      ({
        fullDetails: [fullDetails], error,
      } = await CompaniesSvc.getIndicesFullData(params));
      break;
    }
    case FOREIGN_EXCHANGE: {
      ({
        fullDetails: [fullDetails], error,
      } = await CompaniesSvc.getForeignExchangeFullData(params));
      break;
    }
    default:
      break;
  }
  return {
    fullDetails,
    error,
  };
};

const getPercentageChange = (oldNumber, newNumber) => {
  const decreaseValue = newNumber - oldNumber;
  return (decreaseValue / oldNumber) * 100;
};

export const fetchTopicChartData = async (assetType, params) => {
  let stats;
  let error;
  let percentChange;
  switch (assetType) {
    case CRYPTOCURRENCIES:
      ({ stats, error } = await CryptocurrenciesSvc.getCryptocurrencyStats(params));
      break;
    case PUBLIC_COMPANIES:
    case EXCHANGE_TRADED_FUNDS: {
      ({ stats, error } = await PublicCompaniesSvc.getPublicCompaniesStats(params));
      break;
    }
    case COMMODITIES: {
      ({ stats, error } = await CompaniesSvc.getCommoditiesStats(params));
      break;
    }
    case INDICES: {
      ({ stats, error } = await CompaniesSvc.getIndicesStats(params));
      break;
    }
    case FOREIGN_EXCHANGE: {
      ({ stats, error } = await CompaniesSvc.getForeignExchangeStats(params));
      break;
    }
    default:
      stats = [];
      break;
  }

  if (stats.length > 1) {
    const priceKey = assetType === CRYPTOCURRENCIES ? 'price_usd' : 'price';
    percentChange = getPercentageChange(
      stats[0][priceKey], stats[stats.length - 1][priceKey],
    );
  }

  if (!Number.isFinite(percentChange)) {
    percentChange = undefined;
  }

  return {
    stats,
    error,
    percentChange,
  };
};

export const setTopicAssetType = (assetClass) => {
  let assetType;

  if (assetClass === 'Stocks') {
    assetType = PUBLIC_COMPANIES;
  } else if (assetClass === 'Cryptocurrencies') {
    assetType = CRYPTOCURRENCIES;
  } else if (assetClass === 'Foreign Exchange and Currencies') {
    assetType = FOREIGN_EXCHANGE;
  } else if (assetClass === 'Commodities') {
    assetType = COMMODITIES;
  } else if (assetClass === 'Exchange Traded Funds (ETFs)') {
    assetType = EXCHANGE_TRADED_FUNDS;
  } else if (assetClass === 'Indices') {
    assetType = INDICES;
  }

  return assetType;
};

export const transformTopicAssetData = (topic) => ({
  id: topic.id,
  name: topic.title,
  slug: topic.slug,
  title: topic.title,
  popular: topic.popular,
  itemType: topic.itemType,
  asset_class: 'Topic Class',
});

export const createNewPrivateCompaniesForWatchlist = async (tempTopicsList) => {
  const newCompaniesArr = tempTopicsList.filter((f) => f.isNewCompanies);
  const newWatchlistTopics = tempTopicsList.filter((f) => !f.isNewCompanies);

  const createdCompines = await Promise.all([
    ...newCompaniesArr.map((obj) => (
      TopicsSvc.saveCompaniesData({
        legal_id: obj.companyNumber,
        source: obj.source,
      })
    )),
  ]);

  for (let i = 0; i < createdCompines.length; i += 1) {
    if (createdCompines[i]) {
      newWatchlistTopics.push({
        ...createdCompines[i],
        groups: [{
          groupName: createdCompines[i].id,
          groupType: 'Topic',
        }],
      });
    }
  }

  return newWatchlistTopics;
};

export default fetchTopicFullDetails;
