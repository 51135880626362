// select correct domains mode (blacklist/all/whitelist)
export const filterSourceDomains = (params) => {
  const paramsCopy = {
    ...params,
    ...(Array.isArray(params.whitelisted_domain_ids) && {
      whitelisted_domain_ids: params.whitelisted_domain_ids.join(','),
    }),
    ...(Array.isArray(params.blacklisted_domain_ids) && {
      blacklisted_domain_ids: params.blacklisted_domain_ids.join(','),
    }),
  };

  if (!params.categories) return paramsCopy;

  // if news publications don't selected we remove domains from the params
  if (
    !params.categories.includes('mp')
    || !params.categories.includes('op')
    || params.domains_filtering_mode === 'all'
  ) {
    delete paramsCopy.blacklisted_domain_ids;
    delete paramsCopy.whitelisted_domain_ids;

    return paramsCopy;
  }

  if (params.domains_filtering_mode === 'whitelist') {
    delete paramsCopy.blacklisted_domain_ids;

    return paramsCopy;
  }
  if (params.domains_filtering_mode === 'blacklist') {
    delete paramsCopy.whitelisted_domain_ids;

    return paramsCopy;
  }

  return paramsCopy;
};
