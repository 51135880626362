const generateCustomTooltipStyle = (id) => {
  const styleTag = document.createElement('style');
  styleTag.id = `tooltip-content=${id}`;

  styleTag.innerHTML = `
    .tooltip-content-local-${id}:after {
      left: var(--tooltip-caret-left-${id}, calc(50% - 6px));
    }
  `;

  document.head.appendChild(styleTag);

  return styleTag;
};

export default generateCustomTooltipStyle;
