import camelcaseKeys from 'camelcase-keys';
import isEqual from 'lodash-es/isEqual';
import API, { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { APIRecursiveCall, RetryAPICall } from '../../helpers/apiHelpers';
import {
  cachingHigh, cachingLow, cachingMedium, cachingNewsLow,
} from '../../data/webPageData';

const caches = {
  sentimentsWatchlist: [],
  sentimentsEntity: [],
  sentimentsListings: [],
  sentimentsByEntities: [],
};

export default class SentimentsSvc {
  static async getWatchlistSentiments(newParams = {}) {
    const { isRefresh, ...params } = newParams || {};
    if (isRefresh) {
      caches.sentimentsWatchlist = caches.sentimentsWatchlist
        .filter(({ params: innerParams }) => innerParams.watchlist_id !== params.watchlist_id);
    }
    const cachedItems = caches.sentimentsWatchlist.find((cache) => isEqual(cache.params, params));
    if (cachedItems && cachedItems.expDate > Date.now()) {
      return cachedItems.response;
    }

    const res = RetryAPICall(API, 'sentiments/diagrams/watchlist_chart_data', {
      params,
      headers: {
        'Cache-control': isRefresh ? 'no-cache' : returnAPICachingHeader(cachingMedium),
      },
    });

    caches.sentimentsWatchlist.unshift({
      params,
      expDate: getAPICachingTime(cachingMedium),
      response: res,
    });

    return res;
  }

  static async getTopicSentiments(params = {}, isEtoro) {
    const cachedItems = caches.sentimentsEntity.find((cache) => isEqual(cache.params, params));
    if (cachedItems && cachedItems.expDate > Date.now()) {
      return cachedItems.response;
    }

    let cacheType = params.period === 'hour' ? cachingLow : cachingHigh;
    if (isEtoro) {
      cacheType = cachingNewsLow;
    }

    const res = await APIRecursiveCall(APIWithoutToken, 'sentiments/diagrams/entity_chart_data', {
      params: {
        ...params,
        skipSorting: !!isEtoro,
      },
      headers: {
        'Cache-control': returnAPICachingHeader(cacheType, isEtoro ? 1800 : 900),
      },
    });

    caches.sentimentsEntity.unshift({
      params,
      expDate: getAPICachingTime(cacheType),
      response: res,
    });

    return res;
  }

  static async getListingsSentiments(params = {}) {
    const cachedItems = caches.sentimentsListings.find((cache) => isEqual(cache.params, params));
    if (cachedItems && cachedItems.expDate > Date.now()) {
      return cachedItems.response;
    }

    const cacheType = params.period === '1d' ? cachingLow : cachingMedium;

    const res = await APIWithoutToken.get('sentiments/diagrams/listings', {
      params,
      headers: {
        'Cache-control': returnAPICachingHeader(cacheType),
      },
    });

    caches.sentimentsListings.unshift({
      params,
      expDate: getAPICachingTime(cacheType),
      response: res,
    });

    return res;
  }

  static async getSentimentsOfEntities({
    entities = [],
    period = '1d',
  }) {
    const params = {
      entities: entities.map((entity) => `${entity.type}:${entity.id}`).sort().join(','),
      period,
    };
    const cachedItems = caches.sentimentsByEntities.find((cache) => isEqual(cache.params, params));
    if (cachedItems && cachedItems.expDate > Date.now()) {
      return cachedItems.response;
    }

    const cacheType = params.period === '1d' ? cachingLow : cachingMedium;

    const { data } = await APIWithoutToken.get('sentiments/diagrams/fetch_for_entities', {
      params,
    });
    const sentiments = data?.sentiments ? camelcaseKeys(data.sentiments) : [];

    caches.sentimentsByEntities.unshift({
      params,
      expDate: getAPICachingTime(cacheType),
      response: sentiments,
    });

    return sentiments;
  }
}
