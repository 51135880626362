const CustomSleepFun = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

export const APIRecursiveCall = async (APIRequestType, url, config, count = 1) => {
  try {
    const response = await APIRequestType.get(url, config);
    if (response?.status === 503 && count < 20) {
      await CustomSleepFun(1000);
      return APIRecursiveCall(APIRequestType, url, config, (count || 1) + 1);
    }
    return response;
  } catch (e) {
    if (e?.response?.status === 503 && count < 20) {
      await CustomSleepFun(1000);
      return APIRecursiveCall(APIRequestType, url, config, (count || 1) + 1);
    }

    throw e;
  }
};

export const RetryAPICall = async (APIRequestType,
  url,
  config,
  count = 1,
  sleep = 3000,
  maxRetry) => {
  try {
    const response = await APIRequestType.get(url, config);
    return response;
  } catch (e) {
    if (((e?.response?.status === 502
      || e?.response?.status === 503
      || e?.response?.status === 504
      || e?.response?.status === 522
      || e?.response?.status === 524)
      && count < 3)) {
      await CustomSleepFun(sleep);
      return RetryAPICall(APIRequestType, url, config, (count || 1) + 1);
    }

    if (e?.response?.status === 422 && count <= maxRetry) {
      await CustomSleepFun(sleep * count);
      return RetryAPICall(APIRequestType, url, config, (count || 1) + 1, 20000, 5);
    }
    throw e;
  }
};

export default APIRecursiveCall;
