export { copy } from './copy';
export { capitalize } from './capitalize';

// helper for parsing filters for stories fetch
export const pickParams = (obj, ...itemsToPick) => (
  itemsToPick.filter((i) => obj[i]).reduce((o, i) => ({
    ...o,
    [i]: obj[i],
  }), {})
);

export function stringToBool(value) {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
}

// removing empty properties from
export function removeEmptyProperties(object, keepFalseValues = true) {
  if (!object || typeof object !== 'object') {
    throw new TypeError('Invalid argument passed to the function `removeEmptyProperties`. Object required');
  }

  Object.entries(object).forEach(([prop, value]) => {
    if (!value && value !== 0 && (!keepFalseValues || value !== false)) {
      delete object[prop];
    }
  });
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0)
    : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

export function getBrowserScrollTop() {
  return document.body.scrollTop || document.documentElement.scrollTop;
}

export function isValidEmail(email) {
  return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email);
}

export const getParamValueFromUrl = (param) => {
  if (typeof window === 'undefined') return '';

  const queryString = window.location.search.slice(1);

  // if no query string skip check
  if (!queryString.length) return '';

  const query = queryString.split('&').find((queryParam) => param === queryParam.split('=')[0]);

  return query ? query.split('=')[1] : '';
};
