import isEqual from 'lodash-es/isEqual';
import orderBy from 'lodash-es/orderBy';
import API, { APIWithoutToken } from './dbServices/api';

import {
  getAPICachingTime, returnAPICachingData,
  returnAPICachingHeader,
} from '../helpers/helpers';
import { copy, removeEmptyProperties } from '../helpers/commonHelpers';
import { getTrendingBarAssetQuery } from '../helpers/watchlistHelperFunctions';

import { alphaHeaders } from '../data/directory/constants';
import {
  cachingHigh, cachingVeryHigh,
  cachingNewsHigh,
} from '../data/webPageData';
import isMarketTime from '../helpers/isMarketTime';
import { APIRecursiveCall, RetryAPICall } from '../helpers/apiHelpers';
import { fetchTopicFullDetails, setTopicAssetType } from '../helpers/topicsApiHelpers';
import {
  CRYPTOCURRENCIES, COMMODITIES, INDICES, FOREIGN_EXCHANGE,
} from '../data/directory/topic_classes/topicClassTypes';

import StockExchangesSvc from './dbServices/StockExchangesSvc';

const caches = {
  assetClasses: {},
  assets: [],
  marketShareList: [],
  volumeChartList: [],
  gainersLosersChartList: [],
  cryptoFullDetailsList: [],
  topicAliaseList: [],
};

export default class TopicsSvc {
  static async getAssetsClasses() {
    try {
      if (caches.assetClasses.response && caches.assetClasses.expDate > Date.now()) {
        return caches.assetClasses.response;
      }

      const res = await APIWithoutToken.get('asset_classes', {
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      caches.assetClasses = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: res,
      };

      return res;
    } catch (e) {
      return {};
    }
  }

  static async getAssets(params) {
    const cachedItems = caches.assets.find((cache) => isEqual(cache.params, params));
    if (cachedItems && cachedItems.expDate > Date.now()) {
      return cachedItems.response;
    }

    try {
      const res = await APIWithoutToken.get('assets', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
          // This is a stub header added for CORS headers
          // With this we could force browser request access for custom header
          // in preflight OPTIONS request
          total: '*',
        },
      });

      caches.assets.unshift({
        params,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch (e) {
      return {};
    }
  }

  static async getTopicClasses() {
    const key = 'topicClasses';
    let resolve = () => { };

    if (key in caches) {
      const res = await caches[key];
      return copy(res);
    }

    caches[key] = new Promise((res) => { resolve = res; });

    const result = await this.getAssetsClasses();
    const topicClasses = result?.data?.asset_classes;

    if (!Array.isArray(topicClasses)) throw new Error('Invalid output');

    const res = topicClasses.map((topicClass) => ({
      ...topicClass,
      icon: topicClass.image,
      itemType: 'AssetClass',
    }));

    resolve(res);
    return copy(res);
  }

  static async getTopicClassSlugs(params) {
    try {
      removeEmptyProperties(params);
      let { id } = params;
      const {
        page = 1,
        per_page: perPage = 20,
        prefix = null,
        ...otherParams
      } = params;

      if (typeof id !== 'number') {
        const res = await this.getTopicClassBySlug(id);
        if (!res) return null;
        id = res.id;
      }

      const response = await this.getTopics({
        asset_class_ids: id,
        page,
        per_page: perPage,
        prefix,
        ...otherParams,
      });

      return {
        slug: (response.assets && response.assets.length && response.assets[0].asset_class) || null,
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      };
    } catch (e) {
      return null;
    }
  }

  static async getTopicClassAlphabetHeaders({ id, slugName }) {
    const key = `topicClassAlphabetHeaders_${id}`;

    if (key in caches) return caches[key];

    if (slugName === 'private-companies' || slugName === 'stocks') {
      return new Promise((resolve) => {
        resolve({ alphabetHeaders: alphaHeaders, totalItems: parseInt(alphaHeaders.length, 10) });
      });
    }

    const response = await this.getAssets({
      asset_class_ids: id,
      per_page: 1,
      pagination_alphabet_header: true,
    });

    caches[key] = {
      alphabetHeaders: (
        (response && response.data && response.data.pagination_alphabet_header) || []
      ),
      totalItems: parseInt(response.headers.total, 10),
    };

    return copy(caches[key]);
  }

  static async getTopicClassAndSectorsAlphabetHeaders(params) {
    removeEmptyProperties(params);
    const { sectorId, id } = params;
    const key = `topicClassAndSectorsAlphabetHeaders_${sectorId}_${id}`;

    if (key in caches) return copy(caches[key]);

    const response = await this.getAssets({
      asset_class_ids: id,
      sectors_ids: sectorId,
      per_page: 1,
      pagination_alphabet_header: true,
    });

    caches[key] = {
      alphabetHeaders: (
        (response && response.data && response.data.pagination_alphabet_header) || []
      ),
      totalItems: parseInt(response.headers.total, 10),
    };

    return copy(caches[key]);
  }

  static async getTopicClassAndIndustriesAlphabetHeaders(params) {
    removeEmptyProperties(params);
    const { industryId, id } = params;
    const key = `topicClassAndIndustriesAlphabetHeaders_${industryId}_${id}`;

    if (key in caches) return copy(caches[key]);

    const response = await this.getAssets({
      asset_class_ids: id,
      industries_ids: industryId,
      per_page: 1,
      pagination_alphabet_header: true,
    });

    caches[key] = {
      alphabetHeaders: (
        (response && response.data && response.data.pagination_alphabet_header) || []
      ),
      totalItems: parseInt(response.headers.total, 10),
    };

    return copy(caches[key]);
  }

  static async getTopicClassAndSubIndustriesAlphabetHeaders(params) {
    removeEmptyProperties(params);
    const { subIndustryId, id } = params;
    const key = `topicClassAndSubindustriesAlphabetHeaders_${subIndustryId}_${id}`;

    if (key in caches) return copy(caches[key]);

    const response = await this.getAssets({
      asset_class_ids: id,
      subindustries_ids: subIndustryId,
      per_page: 1,
      pagination_alphabet_header: true,
    });

    caches[key] = {
      alphabetHeaders: (
        (response && response.data && response.data.pagination_alphabet_header) || []
      ),
      totalItems: parseInt(response.headers.total, 10),
    };

    return copy(caches[key]);
  }

  static async getTopicClassAndCategoriesAlphabetHeaders(params) {
    removeEmptyProperties(params);
    const { categoryId, id } = params;
    const key = `topicClassAndCategoriesAlphabetHeaders_${categoryId}_${id}`;

    if (key in caches) return copy(caches[key]);

    const response = await this.getAssets({
      asset_class_ids: id,
      startup_categories_ids: categoryId,
      per_page: 1,
      pagination_alphabet_header: true,
    });

    caches[key] = {
      alphabetHeaders: (
        (response && response.data && response.data.pagination_alphabet_header) || []
      ),
      totalItems: parseInt(response.headers.total, 10),
    };

    return copy(caches[key]);
  }

  static async getTopicClassBySlug(slug) {
    if (typeof slug !== 'string' || !slug.trim()) {
      throw new TypeError('Invalid argument passed to "getTopicClassBySlug" method. Non empty string required');
    }

    const topicClasses = await this.getTopicClasses();

    return (
      topicClasses.find((topicClass) => (
        topicClass.slug && topicClass.slug.toLowerCase() === slug.toLowerCase()
      )) || null
    );
  }

  static async getTopicBySlug(slug) {
    if (typeof slug !== 'string' || !slug.trim()) {
      throw new TypeError('Invalid argument passed to "getTopicBySlug" method. Non empty string required');
    }

    try {
      const key = `topic_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const result = await APIWithoutToken.get('assets/by_slug', {
        params: { slug },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingHigh),
        response: result?.data?.assets?.[0] || null,
      };
      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getTopicById(id) {
    if (typeof id !== 'number' || !Number.isInteger(id) || id < 1) {
      throw new TypeError('Invalid argument passed to "getTopicById" method. Positive integer required');
    }

    try {
      const key = `topic_${id}`;

      if (key in caches) return copy(caches[key]);

      const result = await APIWithoutToken.get(`assets/${id}`, {
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
      });

      caches[key] = result?.data?.assets?.[0] || null;
      return copy(caches[key]);
    } catch (e) {
      return null;
    }
  }

  static async getTopicsBySector(params) {
    try {
      removeEmptyProperties(params);
      const {
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        sectors_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        slug: response.assets[0].sectors[0],
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsByIndustry(params) {
    try {
      removeEmptyProperties(params);
      const {
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        industries_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        slug: response.assets[0].industries[0],
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsBySubIndustry(params) {
    try {
      removeEmptyProperties(params);
      const {
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        subindustries_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        slug: response.assets[0].subindustries[0],
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsByCategory(params) {
    try {
      removeEmptyProperties(params);
      const {
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        subindustries_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        slug: response.assets[0].startup_categories[0],
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsBySectorsAndAssetClass(params) {
    try {
      removeEmptyProperties(params);
      const {
        id,
        sectorsId,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        sectors_ids: sectorsId,
        asset_class_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsByIndustriesAndAssetClass(params) {
    try {
      removeEmptyProperties(params);
      const {
        industriesId,
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        industries_ids: industriesId,
        asset_class_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsBySubIndustriesAndAssetClass(params) {
    try {
      removeEmptyProperties(params);
      const {
        subIndustriesId,
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        subindustries_ids: subIndustriesId,
        asset_class_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopicsByCategoriesAndAssetClass(params) {
    try {
      removeEmptyProperties(params);
      const {
        categoriesId,
        id,
        page = 1,
        per_page: perPage = 20,
        prefix = null,
      } = params;

      const response = await this.getTopics({
        startup_categories_ids: categoriesId,
        asset_class_ids: id,
        page,
        per_page: perPage,
        prefix,
      });

      return response ? {
        assets: response.assets,
        totalPages: Math.ceil(response.total / perPage) || null,
      } : null;
    } catch (e) {
      return null;
    }
  }

  static async getTopics(params) {
    try {
      removeEmptyProperties(params);
      if (params.hasOwnProperty('id')) {
        delete params.id;
      }
      const response = await this.getAssets(params);

      const assets = response && response.data && response.data.assets;

      if (!assets) return null;

      return {
        assets,
        total: parseInt(response.headers.total, 10),
      };
    } catch (e) {
      return null;
    }
  }

  static async getTopicMetaData(topics) {
    try {
      const query = (Array.isArray(topics) ? topics : [topics]).map((topic) => (
        getTrendingBarAssetQuery(topic, 'Topic')
      )).join(' OR ');

      const key = `topicMetaData_${query}`;
      if (key in caches) return copy(caches[key]);

      const { data: { assets } } = await RetryAPICall(API, 'search/query/topics_metadata', {
        params: {
          query,
        },
      });

      let data = null;
      if (assets.length) {
        data = Array.isArray(topics) ? assets : assets[0];
      }

      caches[key] = data;
      return copy(data);
    } catch (e) {
      return null;
    }
  }

  static async getTopicsFullData({
    topics,
  }) {
    const topicsRequests = await Promise.all(
      topics
        .map(async (topic) => {
          const selectors = orderBy(topic?.tickers?.filter((ticker) => ticker.default), 'priority', 'asc')[0];

          const params = {
            page_limit: 1,
            page: 1,
          };
          const assetType = setTopicAssetType(topic.entity_type);
          if (assetType === COMMODITIES || assetType === INDICES
            || assetType === FOREIGN_EXCHANGE) {
            params.identifiers = `TR:${selectors.name}`;
          } else if (assetType === CRYPTOCURRENCIES) {
            params.symbol = selectors.name;
          } else {
            params.symbol = selectors.full_name;
          }

          const stockExchangeMarketHours = await StockExchangesSvc.getStockExchangeMarketHours(
            selectors.stock_exchange_id,
          );
          if (stockExchangeMarketHours) {
            params.market_hours = isMarketTime(stockExchangeMarketHours);
          }

          return fetchTopicFullDetails(assetType, params);
        }),
    );

    topics.forEach((topic) => {
      const assetType = setTopicAssetType(topic.entity_type);
      const tickerKeyName = assetType === CRYPTOCURRENCIES ? 'symbol' : 'ticker';
      let tickerNames = topic?.tickers?.map((ticker) => ticker?.full_name).filter(Boolean);

      if (assetType === CRYPTOCURRENCIES || assetType === COMMODITIES
        || assetType === INDICES || assetType === FOREIGN_EXCHANGE) {
        tickerNames = topic?.tickers?.map((ticker) => ticker?.name).filter(Boolean);
      }

      const dataIndex = topicsRequests.findIndex((topicFullDetails) => (
        !topicFullDetails.error
        && tickerNames.includes(topicFullDetails.fullDetails?.[tickerKeyName])
      ));

      if (dataIndex > -1) {
        Object.assign(topic, {
          companyData: topicsRequests[dataIndex].fullDetails,
        });
      }
    });

    return topics;
  }

  static async getTopicByAliases(params) {
    try {
      const cacheData = returnAPICachingData(caches, 'topicAliaseList', params);
      if (cacheData) return cacheData;

      const { data, error } = await APIRecursiveCall(APIWithoutToken, 'topics/search', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingNewsHigh, 1800),
        },
      });

      if (error) return null;

      caches.topicAliaseList.unshift({
        params,
        expDate: getAPICachingTime(cachingNewsHigh),
        response: data,
      });

      return data;
    } catch (e) {
      return null;
    }
  }

  static async saveCompaniesData(params) {
    try {
      const response = await APIWithoutToken.post(`assets/private_companies/${params.source}/${params.legal_id}`);

      return response.data;
    } catch (e) {
      return null;
    }
  }
}
