import {
  CACHING_ETORO_NEWS_HIGH,
  CACHING_ETORO_NEWS_LOW,
  CACHING_ETORO_NEWS_VERY_HIGH,
  CACHING_ETORO_NON_NEWS_LOW,
  CACHING_HIGH,
  CACHING_LOW,
  CACHING_MEDIUM,
  CACHING_VERY_HIGH,
  CACHING_VERY_LOW, CACHING_VERY_VERY_HIGH,
  CACHING_VERY_VERY_LOW,
} from './environment';

export const hiddenHeaderSearchbarCheck = {
  products: false,
  pricing: false,
  segments: false,
  meetup: false,
  about: false,
  new: false,
  chatbot: false,

  watchlists: true,
  news: true,
  directory: true,
  preferences: true,
  search: true,
  terms: true,
  privacy: true,
  press: true,
  eapi_terms: true,
  sign_in: true,
  'help-videos': true,
  bookmarks: true,
};

export const isRailContextPages = {
  rbi_login: true,
  rbi_search: true,
  rbi_watchlists: true,
};

export const isLeftBarHiddenCheck = {
  users: true,
  products: false,
  pricing: false,
  // news/onboarding all routes starts with
  onboarding: true,
  sign_in: true,
};

export const isMobileLeftBarExistCheck = {
  products: true,
  'financial-enterprise-solutions': true,
};

export const cachingVeryVeryLow = CACHING_VERY_VERY_LOW;
export const cachingVeryLow = CACHING_VERY_LOW;
export const cachingLow = CACHING_LOW;
export const cachingMedium = CACHING_MEDIUM;
export const cachingHigh = CACHING_HIGH;
export const cachingVeryHigh = CACHING_VERY_HIGH;
export const cachingVeryVeryHigh = CACHING_VERY_VERY_HIGH;
export const cachingNewsLow = CACHING_ETORO_NEWS_LOW;
export const cachingNonNewsLow = CACHING_ETORO_NON_NEWS_LOW;
export const cachingNewsHigh = CACHING_ETORO_NEWS_HIGH;
export const cachingNewsVeryHigh = CACHING_ETORO_NEWS_VERY_HIGH;

export const commonAPIDataLimit = 20;
