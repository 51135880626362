export const ONE_DAY = '1d';
export const ONE_WEEK = '1w';
export const ONE_MONTH = '1m';
export const THREE_MONTH = '3m';
export const ONE_YEAR = '1y';
export const FIVE_YEAR = '5y';
export const ALL = 'All';

export const ARRAY_OF_TIME_PERIOD_CHARTS = [
  ONE_DAY, ONE_WEEK, ONE_MONTH, THREE_MONTH, ONE_YEAR, FIVE_YEAR, ALL,
];

export const PRICE_TIME_FILTERS = [
  { text: '1 day', value: '1d' },
  { text: '1 week', value: '1w' },
  { text: '1 month', value: '1m' },
];

export const GRAPH_TIME_FILTERS = [
  ONE_DAY,
  ONE_WEEK, ONE_MONTH,
  THREE_MONTH, ONE_YEAR, FIVE_YEAR,
  ALL,
];

export const GRAPH_TIME_FILTERS_FULL_TEXT = {
  [ONE_DAY]: 'Today',
  [ONE_WEEK]: '1 week',
  [ONE_MONTH]: '1 month',
  [THREE_MONTH]: '3 month',
  [ONE_YEAR]: '1 year',
  [FIVE_YEAR]: '5 year',
  [ALL]: 'Jan 2015 to Today',
};

export const BASIC = 'BASIC';
export const ADVANCED = 'ADVANCED';

export const GRAPH_TYPE_FILTERS = [
  {
    type: BASIC,
    value: 'Basic',
  },
  {
    type: ADVANCED,
    value: 'Advanced',
  },
];

export const ON_CHART_MODAL_TABS = {
  STORIES: 1,
  SUMMARIES: 2,
};
