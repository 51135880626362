import API from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingVeryHigh } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {};

export const getAuthorCategories = async () => {
  try {
    if (caches.authCategories && caches.authCategories.response
      && caches.authCategories.expDate > Date.now()) {
      return caches.authCategories.response;
    }

    const { data } = await RetryAPICall(API, 'author_categories', {
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      },
    });

    caches.authCategories = {
      expDate: getAPICachingTime(cachingVeryHigh),
      response: data,
    };

    return data;
  } catch {
    return [];
  }
};

export const curateStory = (id, params) => (
  API.patch(`stories/${id}/curator_edit`, params)
);

export const getCurationActions = async (params) => {
  try {
    const { data } = await RetryAPICall(API, 'stories/curation_actions', { params });

    return data;
  } catch (e) {
    return {
      user_actions: [],
    };
  }
};
