import getTimezoneOffset from './getTimezoneOffset';

/**
 * @typedef {Object} StockExchangeMarketHours
 * @property {string} id
 * @property {number[]} tradingDays
 * @property {string} openingTime
 * @property {string} closingTime
 * @property {string} timezone
 *
 * @param {StockExchangeMarketHours} stockExchangeMarketHours
 * @return {boolean}
 */
const isMarketTime = (stockExchangeMarketHours) => {
  const openingTime = new Date();
  openingTime.setUTCHours(Number(stockExchangeMarketHours.openingTime.split(':')[0]));
  openingTime.setUTCMinutes(Number(stockExchangeMarketHours.openingTime.split(':')[1]));
  openingTime.setUTCSeconds(0);
  openingTime.setUTCMilliseconds(0);
  // shift date on timezone offset amount
  openingTime.setUTCMinutes(
    openingTime.getUTCMinutes() - getTimezoneOffset(stockExchangeMarketHours.timezone),
  );

  // set market hours 30 minutes before it opens, in order for the cache to expire faster.
  if (stockExchangeMarketHours.openingTime >= '00:30') {
    openingTime.setUTCMinutes(openingTime.getUTCMinutes() - 30);
  }

  const closingTime = new Date();
  closingTime.setUTCHours(Number(stockExchangeMarketHours.closingTime.split(':')[0]));
  closingTime.setUTCMinutes(Number(stockExchangeMarketHours.closingTime.split(':')[1]));
  closingTime.setUTCSeconds(0);
  closingTime.setUTCMilliseconds(0);
  // shift date on timezone offset amount
  closingTime.setUTCMinutes(
    closingTime.getUTCMinutes() - getTimezoneOffset(stockExchangeMarketHours.timezone),
  );

  const now = new Date();
  return (
    stockExchangeMarketHours.tradingDays.some((day) => day === now.getUTCDay())
    && openingTime.getTime() <= Date.now()
    && closingTime.getTime() >= Date.now()
  );
};

export default isMarketTime;
